import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { signup } from '../../api/auth';
import Cookie from 'js-cookie'
import logo from './wordmuse_logo.svg'
import { createDefaultDocument } from '../../api/documents';
import { useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { setCookie } from '../../api/utils/utils';

const theme = createTheme();

export default function SignUp({ setAuthenticated }: { setAuthenticated: (value: boolean) => void }) {
  const navigate = useNavigate()
  const [emptyEmail, setEmptyEmail] = useState(false)
  const [emptyPassword, setEmptyPassword] = useState(false)
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState(false)

  const handleEmailChange = (value: string) => {
    if (value.length === 0) {
      setEmptyEmail(true)
    } else {
      setEmptyEmail(false)
    }
  }

  const handlePasswordChange = (value: string) => {
    if (value.length === 0) {
      setEmptyPassword(true)
    } else {
      setEmptyPassword(false)
    }
  }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data.get('email')
      const password = data.get('password')
      
      if (!email) {
        setEmptyEmail(true)
        return
      }
      if (!password) {
        setEmptyPassword(true)
        return
      }

      setLoading(true)
      setError(null)
      signup(data)
        .then(response => {
          setCookie({ accessToken: response.data.accessToken, refreshToken: response.data.refreshToken })
          setAuthenticated(true)
          setLoading(false)
          navigate('/verify-email')
        })
        .catch(err => {
          setError(err)
          setLoading(false)
        })
    };

  return (
    <ThemeProvider theme={theme}>
      <img src={logo} height={40} width={150} onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {error && <MuiAlert severity='error' onClose={() => setError(null)}>{error?.response?.data?.message}</MuiAlert>}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(event) => handleEmailChange(event.target.value)}
                  error={emptyEmail}
                  helperText={emptyEmail ? 'Email cannot be blank' : ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(event) => handlePasswordChange(event.target.value)}
                  error={emptyPassword}
                  helperText={emptyPassword ? 'Password cannot be blank' : ''}
                />
              </Grid>
            </Grid>
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={emptyEmail || emptyPassword}
            >
              Sign Up
            </LoadingButton>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link onClick={() => navigate('/signin')} variant="body2" style={{ cursor: 'pointer' }}>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}