import * as React from 'react';
import { Navigate, Routes, Route, useNavigate, useLocation, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Documents from '../documents/Documents';
import Editor from '../editor/Editor'
import type { Document } from '../../api/types/documents'
import { DocumentContext } from '../../context/DocumentContext';
import SomethingWentWrong from '../error/SomethingWentWrong';
import VerifyEmail from '../auth/VerifyEmail';
import VerifyEmailSuccess from '../auth/VerifyEmailSuccess';
import { useContext, useEffect } from 'react';
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext';
import { getCookie } from '../../api/utils/utils';
import UserGuide from '../guide/UserGuide';
import { Feedback } from '../feedback/Feedback';

export default function Layout() {
  const [openDocumentId, setOpenDocumentId] = React.useState('new')
  const navigate = useNavigate()
  const location = useLocation()
  const { currentUser, setAuthenticated, setCurrentUser } = useContext(CurrentUserContext) as CurrentUserContextValue

  const showEmailVerifiedPage = 
    !location.pathname.startsWith('/verify-email') 
    && !location.pathname.startsWith('/confirm-email')
    && !currentUser?.emailVerified

  useEffect(() => {
    if (!getCookie()) {
      setAuthenticated(false)
      setCurrentUser(null)
    }

    if (showEmailVerifiedPage) {
      navigate('/verify-email')
    }
    if (currentUser?.emailVerified && location.pathname.startsWith('/verify-email')) {
      navigate('/')
    }
  }, [location.pathname])

  const goToEditor = (id: string) => {
    setOpenDocumentId(id)
    navigate(`/documents/${id}`)
  }

  const createDocument = (newDocument: Document) => {
    setOpenDocumentId(newDocument._id)
    goToEditor(newDocument._id)
  }

  const routes = (
    <Routes>
      <Route path="/" element={<Navigate to="/documents" replace />} />
      <Route path="/signin" element={<Navigate to="/documents" replace />} />
      <Route path="/signup" element={<Navigate to="/documents" replace />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/confirm-email/:verificationToken" element={<VerifyEmailSuccess />} />
      <Route path="/error" element={<SomethingWentWrong showLogo />} />
      <Route path="/documents" element={<Documents create={createDocument} goToEditor={goToEditor} />} />
      <Route path="/documents/:id" element={<Editor />} />
      <Route path="/user-guide" element={<UserGuide />} />
    </Routes>
  )

  const displayErrorPage = location.pathname === '/error'
  const verifyAccountRoute = location.pathname.startsWith('/verify-email') || location.pathname.startsWith('/confirm-email')

  return (
    <DocumentContext.Provider value={{
      openDocumentId,
      setOpenDocumentId
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <CssBaseline />
          <Box
              component="main"
              sx={{
                  backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                      ? theme.palette.grey[100]
                      : theme.palette.grey[900],
                  flexGrow: 1,
                  height: '100vh',
                  overflow: 'auto',
                  position: 'relative'
              }}
          >
              {showEmailVerifiedPage ? <VerifyEmail /> : routes}

              {!showEmailVerifiedPage && <Feedback />}
          </Box>
      </Box>
    </DocumentContext.Provider>
  );
}