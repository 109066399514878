import Cookie from 'js-cookie'
import { Plan } from '../types/auth'

export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/' : 'https://wordmuse.herokuapp.com/'

export const WORDS = {
    [Plan.FREE]: 3000,
    [Plan.HOBBY]: 10000,
    [Plan.NOVEL]: 50000,
    [Plan.MASTERPIECE]: 100000
}  

// TO DO: We can probably get rid of this now
export const getHeaders = () => {
    // TO DO: Make cookie field name a const
    const token = Cookie.get('wordmuseToken')
    return { 
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    }
}