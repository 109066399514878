import axiosInstance from './axios/axios'
import axios from 'axios'
import { BASE_URL } from './const/const'

export const fetchCurrentUser = () => {
    return axiosInstance.get(`${BASE_URL}auth/me`)
}

export const login = (params: FormData) => {
    return axios.post(`${BASE_URL}auth/signin`, JSON.stringify({
        email: params.get('email')?.toString().trim().toLowerCase(),
        password: params.get('password'),
    }), { headers: { 'Content-Type': 'application/json' } })
}

export const logout = () => {
    return axiosInstance.post(`${BASE_URL}auth/logout`)
}

export const signup = (params: FormData) => {
    return axios.post(`${BASE_URL}auth/signup`, JSON.stringify({
        email: params.get('email')?.toString().trim().toLowerCase(),
        password: params.get('password'),
    }), { headers: { 'Content-Type': 'application/json' } })
}

export const forgotPassword = (params: FormData) => {
    return axios.post(`${BASE_URL}auth/forgot-password`, JSON.stringify({
        email: params.get('email')?.toString().trim().toLowerCase()
    }), { headers: { 'Content-Type': 'application/json' } })
}

export const validateResetToken = (params: string) => {
    return axiosInstance.post(`${BASE_URL}auth/validate-reset-token`, JSON.stringify({
        resetPasswordToken: params
    }), { headers: { 'Content-Type': 'application/json' } })
}

export const resetPassword = (params: { email: string, password: string, resetPasswordToken: string }) => {
    return axiosInstance.post(`${BASE_URL}auth/reset-password`, JSON.stringify({
        email: params.email,
        password: params.password,
        resetPasswordToken: params.resetPasswordToken
    }), { headers: { 'Content-Type': 'application/json' } })
}

export const sendVerificationEmail = (params: { userId: string, email: string }) => {
    return axiosInstance.post(`${BASE_URL}auth/send-verification-email`, JSON.stringify({
        userId: params.userId,
        email: params.email
    }), { headers: { 'Content-Type': 'application/json' } })
}

export const verifyEmailAddress = (params: { userId: string, verificationToken: string }) => {
    return axiosInstance.post(`${BASE_URL}auth/verify-email`, JSON.stringify({
        userId: params.userId,
        verificationToken: params.verificationToken
    }), { headers: { 'Content-Type': 'application/json' } })
}