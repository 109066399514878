import { Box, Typography } from "@mui/material";
import { ToolTextArea } from './Elements'

type EssayOutlineFormProps = {
    error: boolean
    topic: string
    instructions: string
    handleTopicChange: (value: string) => void
    handleInstructionsChange: (value: string) => void
}

export default function EssayOutlineForm({ error, topic, instructions, handleTopicChange, handleInstructionsChange }: EssayOutlineFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Topic</Typography>
                <ToolTextArea error={!topic.length && error} placeholder={'The origins of American cuisine'} value={topic} onChange={(event) => handleTopicChange(event.target.value)} />
            </Box>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Box display='flex'>
                    <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginRight: '0.25rem'}}>Instructions</Typography>
                    <Typography fontSize={14} style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem'}}>(optional)</Typography> 
                </Box>
                <ToolTextArea placeholder={'Include sections on specific types of foods'} value={instructions} onChange={(event) => handleInstructionsChange(event.target.value)} />
            </Box>
        </>
    )
}