import { Box, Typography } from "@mui/material";
import { ToolInput, ToolTextArea } from './Elements'

type WorldbuildingFormProps = {
    error: boolean
    setting: string
    description: string
    handleSettingChange: (value: string) => void
    handleDescriptionChange: (value: string) => void
}

export default function WorldbuildingForm({ error, setting, description, handleSettingChange, handleDescriptionChange }: WorldbuildingFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Setting</Typography>
                <ToolInput error={!setting.length && error} placeholder={'A planet at the edge of the galaxy'} value={setting} onChange={(event) => handleSettingChange(event.target.value)} />
            </Box>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Story Concept</Typography>
                <ToolTextArea error={!description.length && error} placeholder={'The first human on a newly discovered planet finds that she is not alone.'} value={description} onChange={(event) => handleDescriptionChange(event.target.value)} />
            </Box>
        </>
    )
}