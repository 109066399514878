import { Box, Typography } from "@mui/material";
import { ToolInput, ToolTextArea } from './Elements'

type StoryTitleFormProps = {
    error: boolean
    genre: string
    description: string
    handleGenreChange: (value: string) => void
    handleDescriptionChange: (value: string) => void
}

export default function StoryTitleForm({ error, genre, description, handleGenreChange, handleDescriptionChange }: StoryTitleFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Genre</Typography>
                <ToolInput error={!genre.length && error} placeholder={'Humor'} value={genre} onChange={(event) => handleGenreChange(event.target.value)} />
            </Box>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Story Concept</Typography>
                <ToolTextArea error={!description.length && error} placeholder={'After a night of questionable decisions, a man wakes up with a missing finger.'} value={description} onChange={(event) => handleDescriptionChange(event.target.value)} />
            </Box>
        </>
    )
}