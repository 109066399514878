import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './wordmuse_logo.svg'
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { Paper } from '@mui/material';
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext';
import Cookie from 'js-cookie'
import { logout, sendVerificationEmail } from '../../api/auth';
import { removeCookie } from '../../api/utils/utils';

const theme = createTheme();

export default function VerifyEmail() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const { currentUser, setCurrentUser, setAuthenticated } = useContext(CurrentUserContext) as CurrentUserContextValue

    const handleSubmit = async () => {
        if (currentUser) {
            setLoading(true)
            sendVerificationEmail({ userId: currentUser._id, email: currentUser.email })
                .then(() => {
                    setEmailSent(true)
                    setLoading(false)
                })
                .catch(err => {
                    // TO DO: Display error
                    setLoading(false)
                })
        }

    }

    const signOut = () => {
        logout()
            .finally(() => {
                removeCookie()
                setAuthenticated(false)
                setCurrentUser(null)
            })
    }

    return (
        <ThemeProvider theme={theme}>
        <Box display='flex' justifyContent='space-between'>
            <img src={logo} height={40} width={150} onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />
            <Button onClick={signOut} style={{marginRight: '2rem', marginTop: '0.5rem'}}>Logout</Button>
        </Box>

        <Container component="main" maxWidth="xs" sx={{ marginTop: emailSent ? 0 : 8 }}>
            <CssBaseline />
            {emailSent && <MuiAlert severity='success' onClose={() => setEmailSent(false)} style={{marginBottom: '1rem'}}>Verification email sent</MuiAlert>}
            <Paper style={{padding: '2rem', paddingTop: '2rem'}}>
                
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Verify Your Email Address
                    </Typography>

                    <Box marginTop='0.5rem' display='flex' flexDirection='column' justifyContent='center' width='100%'>
                        <Typography style={{marginBottom: '1rem', textAlign: 'center'}}>Click the link in your inbox to activate your account.</Typography>
                        <LoadingButton
                            loading={loading}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={handleSubmit}
                        >
                            Resend email
                        </LoadingButton>
                    </Box>
                </Box>
            </Paper>
        </Container>
        </ThemeProvider>
    );
}