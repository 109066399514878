import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookie from 'js-cookie'
import { resetPassword, validateResetToken } from '../../api/auth';
import logo from './wordmuse_logo.svg'
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

const theme = createTheme();

export default function ResetPassword() {
    const navigate = useNavigate()
    const params = useParams();
    const [updated, setUpdated] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emptyPassword, setEmptyPassword] = useState(false)
    const [error, setError] = useState<any>()
    const [tokenError, setTokenError] = useState<any>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (params.resetPasswordToken) {
            validateResetToken(params.resetPasswordToken)
                .then(response => {
                    setEmail(response.data.email)
                })
                .catch(err => {
                    setTokenError(err)
                })
        }
    }, [])

    const handlePasswordChange = (value: string) => {
        if (value.length === 0) {
            setEmptyPassword(true)
        } else {
            setEmptyPassword(false)
        }
        setPassword(value)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!password) {
          setEmptyPassword(true)
          return
        }

        if (params.resetPasswordToken) {
            setLoading(true)
            setError(null)
            resetPassword({ email, password, resetPasswordToken: params.resetPasswordToken })
                .then(response => {
                    setUpdated(true)
                    setLoading(false)
                })
                .catch(err => {
                    setError(err)
                    setUpdated(false)
                    setLoading(false)
                })
        }
    };

    return (
        <ThemeProvider theme={theme}>
        <img src={logo} height={40} width={150} onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />

        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {error && <MuiAlert severity='error' onClose={() => setError(null)}>{error?.response?.data?.message}</MuiAlert>}
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>

                {updated && (
                    <Box marginTop='0.5rem' display='flex' flexDirection='column' justifyContent='center'>
                    <Typography style={{marginBottom: '1rem'}}>Password successfully updated.</Typography>
                    <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                        <Grid item>
                        <Link onClick={() => navigate('/signin')} variant="body2" style={{ cursor: 'pointer' }}>
                            {"Sign in"}
                        </Link>
                        </Grid>
                    </Grid>
                    </Box>
                )}

                {tokenError && <Typography style={{marginTop: '0.5rem'}}>Reset password link is expired or invalid</Typography>}

                {!tokenError && !updated && (
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="New Password"
                            type="password"
                            id="password"
                            onChange={(event) => handlePasswordChange(event.target.value)}
                            error={emptyPassword}
                            helperText={emptyPassword ? 'Password cannot be blank' : ''}
                            value={password}
                        />
                        <LoadingButton
                            loading={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={emptyPassword}
                        >
                            Update Password
                        </LoadingButton>
                        <Grid container>
                            <Grid item>
                                <Link onClick={() => navigate('/signin')} variant="body2" style={{ cursor: 'pointer' }}>
                                    {"Sign in"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Box>
        </Container>
        </ThemeProvider>
    );
}