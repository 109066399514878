import { useState } from "react"

export function useNoToolForm() {
    const [tone, setTone] = useState('')

    const handleToneChange = (value: string) => {
        setTone(value)
    }

    return {
        tone,
        handleToneChange
    }
}

export function useCharacterListForm() {
    const [concept, setConcept] = useState('')

    const handleConceptChange = (value: string) => {
        setConcept(value)
    }

    return {
        concept,
        handleConceptChange
    }
}

export function useCharacterBackstoryForm() {
    const [description, setDescription] = useState('')

    const handleDescriptionChange = (value: string) => {
        setDescription(value)
    }

    return {
        description,
        handleDescriptionChange,
    }
}

export function useOutlineBuilderForm() {
    const [description, setDescription] = useState('')

    const handleDescriptionChange = (value: string) => {
        setDescription(value)
    }

    return {
        description,
        handleDescriptionChange,
    }
}

export function useWorldbuildingForm() {
    const [setting, setSetting] = useState('')
    const [description, setDescription] = useState('')

    const handleSettingChange = (value: string) => {
        setSetting(value)
    }

    const handleDescriptionChange = (value: string) => {
        setDescription(value)
    }

    return {
        setting,
        description,
        handleSettingChange,
        handleDescriptionChange,
    }
}

export function useStoryTitleForm() {
    const [genre, setGenre] = useState('')
    const [description, setDescription] = useState('')

    const handleGenreChange = (value: string) => {
        setGenre(value)
    }

    const handleDescriptionChange = (value: string) => {
        setDescription(value)
    }

    return {
        genre,
        description,
        handleGenreChange,
        handleDescriptionChange,
    }
}

export function useConceptForm() {
    const [setting, setSetting] = useState('')
    const [character, setCharacter] = useState('')

    const handleSettingChange = (value: string) => {
        setSetting(value)
    }

    const handleCharacterChange = (value: string) => {
        setCharacter(value)
    }

    return {
        setting,
        character,
        handleSettingChange,
        handleCharacterChange
    }
}

export function useEssayOutlineForm() {
    const [topic, setTopic] = useState('')
    const [instructions, setInstructions] = useState('')

    const handleTopicChange = (value: string) => {
        setTopic(value)
    }

    const handleInstructionsChange = (value: string) => {
        setInstructions(value)
    }

    return {
        topic,
        instructions,
        handleTopicChange,
        handleInstructionsChange
    }
}

export function useDescriptionForm() {
    const [description, setDescription] = useState('')

    const handleDescriptionChange = (value: string) => {
        setDescription(value)
    }

    return {
        description,
        handleDescriptionChange,
    }
}

export function useIntroForm() {
    const [description, setDescription] = useState('')

    const handleDescriptionChange = (value: string) => {
        setDescription(value)
    }

    return {
        description,
        handleDescriptionChange
    }
}

export function useDefaultForm() {
    const [description, setDescription] = useState('')

    const handleDescriptionChange = (value: string) => {
        setDescription(value)
    }

    return {
        description,
        handleDescriptionChange
    }
}