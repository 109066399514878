import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiAlert from '@mui/material/Alert';
import { deleteDocument } from '../../api/documents';
import { useNavigate } from 'react-router-dom';
import { Button as MuiButton, CircularProgress } from '@mui/material';
import type { Document } from '../../api/types/documents'
import { useEffect, useState } from 'react';

type DeleteModalProps = {
    close: () => void
    document: Document
    open: boolean
}

export function DeleteModal ({ close, document, open }: DeleteModalProps) {
    const navigate = useNavigate()
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    const confirmDeleteDocument = async () => {
      setLoading(true)
      setError(null)
        deleteDocument(document)
            .then(() => {
                setLoading(false)
                navigate('/documents')
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }

    return (
        <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {error && <MuiAlert severity='error' onClose={() => setError(null)}>Failed to delete document.</MuiAlert>}
        <DialogTitle id="alert-dialog-title">
          Delete {document.title.length > 0 ? `"${document.title}"` : 'untitled document'}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleted documents cannot be restored. Choose wisely!
          </DialogContentText>
          {loading && <CircularProgress color='secondary' style={{position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)'}} />}
        </DialogContent>
        <DialogActions>
          <MuiButton disabled={loading} onClick={close} autoFocus>Cancel</MuiButton>
          <MuiButton disabled={loading} color='error' variant='contained' onClick={confirmDeleteDocument}>
            Delete
          </MuiButton>
        </DialogActions>
      </Dialog>
    )
}