import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { User } from '../api/types/auth'

export interface CurrentUserContextValue {
    authenticated: boolean
    setAuthenticated: Dispatch<SetStateAction<boolean>>
    currentUser: User | null
    setCurrentUser: Dispatch<SetStateAction<User | null>>
}

export const CurrentUserContext = React.createContext<CurrentUserContextValue | null>(null)