import { Node } from 'slate'
import { CHARS_PER_WORD } from '../const/const'

export const capitalize = (text: string) => {
    return text[0].toUpperCase() + text.substring(1)
}

export const formatNumber = (number: number) => {
    return number.toLocaleString("en-US")
}

export const serialize = (nodes: any[]) => {
    return nodes.map(node => Node.string(node)).join('\n')
}

export const getLastCharacters = (text: string) => {
    const charPerWord = CHARS_PER_WORD
    const wordLimit = 500
    const charLimit = charPerWord * wordLimit

    if (text.length <= charLimit) {
        return text
    }

    return text.substring(text.length - charLimit)
}