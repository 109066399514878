export enum Role {
    USER = 'user',
    ADMIN = 'admin'
}

export enum Plan {
    FREE = 'free',
    HOBBY = 'hobby',
    NOVEL = 'novel',
    MASTERPIECE = 'masterpiece'
}

export interface User {
    _id: string
    email: string
    emailVerified: boolean
    role: Role
    plan: Plan
    wordsQuota: number
    wordsUsed: number
    customerId: string
    subscriptionStatus: string
}