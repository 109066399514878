import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookie from 'js-cookie'
import { login } from '../../api/auth';
import logo from './wordmuse_logo.svg'
import { useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { setCookie } from '../../api/utils/utils';

const theme = createTheme();

export default function SignIn({ setAuthenticated }: { setAuthenticated: (value: boolean) => void }) {
  const navigate = useNavigate()
  const [emptyEmail, setEmptyEmail] = useState(false)
  const [emptyPassword, setEmptyPassword] = useState(false)
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState(false)

  const handleEmailChange = (value: string) => {
    if (value.length === 0) {
      setEmptyEmail(true)
    } else {
      setEmptyEmail(false)
    }
  }

  const handlePasswordChange = (value: string) => {
    if (value.length === 0) {
      setEmptyPassword(true)
    } else {
      setEmptyPassword(false)
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')
    const password = data.get('password')
    
    if (!email) {
      setEmptyEmail(true)
      return
    }
    if (!password) {
      setEmptyPassword(true)
      return
    }

    setLoading(true)
    setError(null)
    login(data)
      .then(response => {
        setCookie({ accessToken: response.data.accessToken, refreshToken: response.data.refreshToken })
        setAuthenticated(true)
        setLoading(false)
        navigate('/')
      })
      .catch(err => {
        setError(err)
        setLoading(false)
      })
};

  return (
    <ThemeProvider theme={theme}>
      <img src={logo} height={40} width={150} onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {error && <MuiAlert severity='error' onClose={() => setError(null)}>{error?.response?.data?.message || 'Failed to sign in'}</MuiAlert>}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(event) => handleEmailChange(event.target.value)}
              error={emptyEmail}
              helperText={emptyEmail ? 'Email cannot be blank' : ''}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => handlePasswordChange(event.target.value)}
              error={emptyPassword}
              helperText={emptyPassword ? 'Password cannot be blank' : ''}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <LoadingButton
              loading={loading}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={emptyEmail || emptyPassword}
            >
              Sign In
            </LoadingButton>
            <Grid container>
              <Grid item xs>
                <Link onClick={() => navigate('/forgot-password')} variant="body2" style={{ cursor: 'pointer' }}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={() => navigate('/signup')} variant="body2" style={{ cursor: 'pointer' }}>
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}