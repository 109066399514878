import type { Document } from './types/documents'
import axios from './axios/axios'
import { BASE_URL, getHeaders } from './const/const'
import { getLastCharacters, serialize } from '../utils/utils'

type Form = {[key: string]: string}

export const openAiContinue = async (document: Document, form: Form, tool: string) => {
    const input = tool === 'none' ? getLastCharacters(serialize(document.content)) : ''
    const body = JSON.stringify({ tool, input, form })

    return await axios.post(`${BASE_URL}outputs/completions`, body, getHeaders())
}

export const openAiExpand = async (text: string) => {
    const input = getLastCharacters(text)
    const body = JSON.stringify({ input })

    return await axios.post(`${BASE_URL}outputs/expand`, body, getHeaders())
}

export const fetchCompletions = async () => {
    return await axios.get(`${BASE_URL}outputs`, getHeaders())
}