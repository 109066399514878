import { Box, Typography } from "@mui/material";
import { ToolTextArea } from './Elements'

type CharacterBackstoryFormProps = {
    error: boolean
    description: string
    handleDescriptionChange: (value: string) => void
}

export default function CharacterBackstoryForm({ error, description, handleDescriptionChange }: CharacterBackstoryFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Character</Typography>
                <ToolTextArea error={!description.length && error} placeholder={'A lonely panda in the San Diego zoo who dreams of escaping back home'} value={description} onChange={(event) => handleDescriptionChange(event.target.value)} />
            </Box>
        </>
    )
}