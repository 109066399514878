import Cookie from 'js-cookie'

export const getCookie = () => {
    const cookie = Cookie.get('wordmuse')
    return cookie ? JSON.parse(cookie || '') : {}
}

export const setCookie = (data: { accessToken: string, refreshToken: string }) => {
    Cookie.set('wordmuse', JSON.stringify({ accessToken: data.accessToken, refreshToken: data.refreshToken }))
}

export const removeCookie = () => {
    Cookie.remove('wordmuse')
}