import { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, Card, CardActionArea, CardContent, CardMedia, IconButton, Link, TextField } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext';
import CharacterListForm from './tools/CharacterListForm';
import CharacterBackstoryForm from './tools/CharacterBackstoryForm';
import { useCharacterBackstoryForm, useCharacterListForm, useConceptForm, useDefaultForm, useDescriptionForm, useEssayOutlineForm, useIntroForm, useNoToolForm, useOutlineBuilderForm, useStoryTitleForm, useWorldbuildingForm } from './tools/hooks';
import DefaultForm from './tools/DefaultForm';
import NoToolForm from './tools/NoToolForm';
import WorldbuildingForm from './tools/WorldbuildingForm';
import OutlineBuilderForm from './tools/OutlineBuilderForm';
import StoryTitleForm from './tools/StoryTitleForm';
import ConceptForm from './tools/ConceptForm';
import EssayOutlineForm from './tools/EssayOutlineForm';
import DescriptionForm from './tools/DescriptionForm';
import IntroForm from './tools/IntroForm';
import { useNavigate } from 'react-router-dom';
import { WindowSizeContext, WindowSizeContextValue } from '../../context/WindowSizeContext';

type PanelProps = {
    openToolModal: () => void
    currentTool: string
    completionLoading: boolean
    continueWriting: (form: any) => void
}

export default function Panel({ continueWriting, completionLoading, openToolModal, currentTool }: PanelProps) {
    const navigate = useNavigate()
    const { currentUser } = useContext(CurrentUserContext) as CurrentUserContextValue

    const { windowSize } = useContext(WindowSizeContext) as WindowSizeContextValue
    const snapLayout = windowSize.innerWidth < 900
    const tabIconsOnly = windowSize.innerWidth < 700

    const [emptyFieldError, setEmptyFieldError] = useState(false)
    
    const noToolFormProps = useNoToolForm()
    const characterListFormProps = useCharacterListForm()
    const characterBackstoryFormProps = useCharacterBackstoryForm()
    const outlineBuilderFormProps = useOutlineBuilderForm()
    const conceptFormProps = useConceptForm()
    const worldbuildingFormProps = useWorldbuildingForm()
    const storyTitleFormProps = useStoryTitleForm()
    const essayOutlineFormProps = useEssayOutlineForm()
    const descriptionFormProps = useDescriptionForm()
    const introFormProps = useIntroForm()
    const defaultFormProps = useDefaultForm()

    // TO DO: Don't let them go over if too few words remaining
    const outputCallsDisabled = currentUser ? (currentUser.wordsQuota - currentUser.wordsUsed <= 0): true

    const tools = [
        { name: 'outline-builder', title: 'Plot Outline', description: 'Build an outline for your story'},
        { name: 'character-list', title: 'Character List', description: 'Find interesting characters for your story'},
        { name: 'character-backstory', title: 'Backstory', description: 'Learn about your character\'s past'},
        { name: 'concept', title: 'Story Concept', description: 'Find a concept for your new story'},
        { name: 'worldbuilding', title: 'Worldbuilding', description: 'Explore the world where your story takes place'},
        { name: 'story-title', title: 'Title', description: 'Find a great title for your story'},
        { name: 'essay-outline', title: 'Essay Outline', description: 'Generate an essay outline for any topic'},
        { name: 'description', title: 'Description', description: 'Describe a person, place, or thing'},
        { name: 'intro', title: 'Story Intro', description: 'Kick off your story with an intro paragraph'},
    ]

    const selectedTool = tools.find(tool => tool.name === currentTool)

    const getFormProps = () => {
        switch(currentTool) {
            case 'none':
                return noToolFormProps
            case 'character-list':
                return characterListFormProps
            case 'character-backstory':
                return characterBackstoryFormProps
            case 'worldbuilding':
                return worldbuildingFormProps
            case 'outline-builder':
                return outlineBuilderFormProps
            case 'story-title':
                return storyTitleFormProps
            case 'essay-outline':
                return essayOutlineFormProps
            case 'concept':
                return conceptFormProps
            case 'description':
                return descriptionFormProps
            case 'intro':
                return introFormProps
            default:
                return defaultFormProps
        }
    }

    const renderForm = () => {
        switch(currentTool) {
            case 'none':
                return <NoToolForm {...noToolFormProps} />
            case 'character-list':
                return <CharacterListForm {...characterListFormProps} error={emptyFieldError} />
            case 'character-backstory':
                return <CharacterBackstoryForm {...characterBackstoryFormProps} error={emptyFieldError} />
            case 'worldbuilding':
                return <WorldbuildingForm {...worldbuildingFormProps} error={emptyFieldError} />
            case 'outline-builder':
                return <OutlineBuilderForm {...outlineBuilderFormProps} error={emptyFieldError} />
            case 'story-title':
                return <StoryTitleForm {...storyTitleFormProps} error={emptyFieldError} />
            case 'essay-outline':
                return <EssayOutlineForm {...essayOutlineFormProps} error={emptyFieldError} />
            case 'concept':
                return <ConceptForm {...conceptFormProps} error={emptyFieldError} />
            case 'description':
                return <DescriptionForm {...descriptionFormProps} error={emptyFieldError} />
            case 'intro':
                return <IntroForm {...introFormProps} error={emptyFieldError} />
            default:
                return <DefaultForm {...defaultFormProps} error={emptyFieldError} />
        }
    }

    const form = renderForm()
    const formProps = getFormProps()

    const submit = () => {
        const isEmptyField = checkForEmptyFields()

        // TO DO: Allow for optional fields to be left blank
        if (!isEmptyField || currentTool === 'none') {
            setEmptyFieldError(false)
            continueWriting(formProps)
        } else {
            setEmptyFieldError(true)
        }
    }

    const checkForEmptyFields = () => {
        let isEmptyField = false

        Object.keys(formProps).some(key => {
            // @ts-ignore
            const prop = formProps[key]
            if (typeof prop === 'string' && prop.length === 0) {
                isEmptyField = true
            }
        })
        
        return isEmptyField
    }

    const openToolSelectorModal = () => {
        setEmptyFieldError(false)
        openToolModal()
    }

    function ToolCard () {
        return (
            <Box display='flex' justifyContent='center'>
                {selectedTool ? (
                    <ActionAreaCard 
                        title={selectedTool.title} 
                        description={selectedTool.description}
                        onClick={openToolSelectorModal}
                    />
                ) : <DefaultCard onClick={openToolSelectorModal} />}
            </Box>
        )
    }

    return (
        <Box display='flex' flexDirection='column' style={{padding: '1.5rem', paddingTop: '1.25rem', paddingBottom: '1.5rem', maxHeight: '100%', maxWidth: 500, height: snapLayout ? 'calc(100vh - 60px)' : '100%', marginLeft: 'auto', marginRight: 'auto' }}>
            {!snapLayout && (
                <Box style={{marginBottom: '0.5rem'}}>
                    <Button onClick={() => navigate('/documents')}>
                        <WestIcon style={{marginRight: '0.5rem'}} />
                        Documents
                    </Button>
                </Box>
            )}

            <ToolCard />

            <Box style={{marginTop: '1rem'}}>
                {form}
            </Box>

            <Button variant='contained' color='secondary' disabled={outputCallsDisabled || completionLoading} onClick={submit}>{outputCallsDisabled ? 'Word limit reached' : 'Write'}</Button> 

            {currentTool === 'none' && (
                <Box style={{marginTop: '2rem', border: '1px dashed #ccc', borderRadius: 4, padding: '1rem'}}>
                    <Typography fontSize={14}><strong>Quick Tip:</strong>{' '}Write at least a paragraph in the editor before generating AI text for best results.</Typography>
                </Box>
            )}

            {currentTool === 'character-list' && (
                <Box style={{marginTop: '2rem', border: '1px dashed #ccc', borderRadius: 4, padding: '1rem'}}>
                    <Typography fontSize={14}><strong>Quick Tip:</strong>{' '}Mentioning the setting in your story concept can help improve the accuracy of the AI’s response.</Typography>
                </Box>
            )}

        </Box>
    )
}

type ActionAreaCardProps = { 
    image?: string
    title: string
    description: string
    onClick: () => void
}

function ActionAreaCard({ image, title, description, onClick }: ActionAreaCardProps) {
    return (
      <Box onClick={onClick} style={{textDecoration: 'none', width: '100%'}}>
        <Card style={{
            backgroundImage: 'linear-gradient(#9795ef, #f9c5d1)'
        }}>
          <CardActionArea>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    );
}

type DefaultCardProps = { 
    onClick: () => void
}

function DefaultCard({ onClick }: DefaultCardProps) {
    return (
      <Box onClick={onClick} style={{textDecoration: 'none', width: '100%'}}>
        <Card style={{
            backgroundImage: 'linear-gradient(#9795ef, #f9c5d1)'
        }}>
          <CardActionArea>
              <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="body2" color="text.secondary" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <Typography style={{marginBottom: '0.25rem'}}><strong>Continue Writing</strong></Typography>
                    <Box>
                        or{' '}<Link style={{cursor: 'pointer', textDecoration: 'none'}}>Brainstorm</Link>
                    </Box>
                </Typography>
              </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    );
}