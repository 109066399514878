import { Box, Button, CircularProgress, Divider, IconButton, Typography } from "@mui/material"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useEffect, useState } from "react";
import { fetchCompletions } from "../../api/openAi";
import moment from "moment";

type HistoryProps = {
    insertIntoDocument: (value: string) => void
}

const tools = [
    { name: 'outline-builder', title: 'Plot Outline', description: 'Build an outline for your story' },
    { name: 'character-list', title: 'Character List', description: 'Find interesting characters for your story'},
    { name: 'character-backstory', title: 'Backstory', description: 'Learn about your character\'s past'},
    { name: 'concept', title: 'Story Concept', description: 'Find a concept for your new story'},
    { name: 'worldbuilding', title: 'Worldbuilding', description: 'Explore the world where your story takes place'},
    { name: 'story-title', title: 'Title', description: 'Find a great title for your story'},
    { name: 'essay-outline', title: 'Essay Outline', description: 'Generate an essay outline for any topic'},
    { name: 'description', title: 'Description', description: 'Describe a person, place, or thing'},
    { name: 'intro', title: 'Story Intro', description: 'Kick off your story with an intro paragraph'},
    { name: 'none', title: 'Continue writing', description: 'Continue writing the story'},
    { name: 'expand', title: 'Expand Text', description: 'Expand a word or sentence into a paragraph'},
]

const numberAlphabetMap: {[key: number]: string} = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E'
}

export function History({ insertIntoDocument }: HistoryProps) {
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>(null)
    const [addedOutputIndices, setAddedOutputIndices] = useState<number[][]>([])
    
    let lastDate: Date

    useEffect(() => {
        setLoading(true)
        setError(null)
        fetchCompletions()
            .then(response => {
                // @ts-ignore
                setData(response.data.reverse())
                setLoading(false)
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }, [])

    const insert = (outputIndex: number, choiceIndex: number) => {
        const output = data[outputIndex]

        insertIntoDocument(output.choices[choiceIndex].text.trim())

        const outputChoiceAdded = isOutputChoiceAdded(outputIndex, choiceIndex)

        if (!outputChoiceAdded) {
            setAddedOutputIndices([...addedOutputIndices, [outputIndex, choiceIndex]])
        }
    }

    const isOutputChoiceAdded = (outputIndex: number, choiceIndex: number) => {
        return addedOutputIndices.some(tuple => tuple[0] === outputIndex && tuple[1] === choiceIndex)
    }

    const getFormattedDate = (createdAt: Date) => {
        if (!lastDate || (moment(createdAt).format("MMM D, YYYY") !== moment(lastDate).format("MMM D, YYYY"))) {
            lastDate = createdAt
            return moment(createdAt).format("MMM D, YYYY")
        } else {
            return ''
        }
    }

    return (
        <Box display='flex' flexDirection='column' maxHeight='100%'>
            <Box style={{ height: 54, flexShrink: 0, borderBottom: '2px solid #eee', display: 'flex', alignItems: 'center', paddingLeft: '1.5rem' }}>
                <strong>Generated Text ({data.length})</strong>
            </Box>

            <Box padding='1.5rem' style={{ position: 'relative', overflowY: 'scroll', height: 'calc(100vh - 114px)'}}>
                <Box flex={1} display={loading ? 'flex' : 'none'} style={{position: 'absolute', top: 'calc(50% - 40px)', left: 'calc(50% - 20px)', zIndex: 999 }}>
                    <Box display='flex' flex={1} justifyContent='center' alignItems='center'>
                        <CircularProgress color="secondary" />
                    </Box>
                </Box>

                {error && (
                    <Box display='flex' flex={1} justifyContent='center' alignItems='center' height='100%'>
                        <ErrorOutlineIcon color='error' style={{marginRight: '0.25rem'}} />
                        <Typography>{'Failed to fetch history. Please try again.'}</Typography>
                    </Box>
                )}

                {!data.length && !loading && !error && (
                    <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
                        <Typography>No text generated</Typography>
                    </Box>
                )}

                {!error && data.map((output: any, outputIndex: number) => {
                    const dateFormatted = getFormattedDate(output.createdAt)
                    const toolTitle = output.tool ? tools.find(item => item.name === output.tool)?.title : ''

                    return (
                        <Box key={outputIndex} style={{marginBottom: '1.5rem' }}>
                            {dateFormatted.length > 0 && <Typography style={{textAlign: 'center', marginBottom: '3.5rem', marginTop: outputIndex > 0 ? '3.5rem' : 0}}>{dateFormatted}</Typography>}
                            <Box style={{position: 'relative', backgroundColor: '#ba55d315', borderRadius: 12, minHeight: '4rem', display: 'flex', flexDirection: 'column', padding: '1.5rem 1.5rem' }}>
                                <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>{toolTitle}</Typography>

                                <Box>
                                    <Typography fontSize={15}>
                                        {/* @ts-ignore */}
                                        {output.choices.map((choice, choiceIndex) => {
                                            const numChoices = output.choices.length
                                            const lastChoice = choiceIndex === numChoices - 1
                                            const added = isOutputChoiceAdded(outputIndex, choiceIndex)
                                            return (
                                                <>
                                                    <Box marginBottom={lastChoice ? 0 : '3rem'}>
                                                        <p key={choiceIndex} style={{ marginTop: 0, display: 'flex' }}>
                                                            {numChoices > 1 && <Typography fontWeight='bold' style={{marginBottom: '0.75rem', marginRight: '0.5rem'}}>{output.tool === 'none' ? numberAlphabetMap[choiceIndex] : (choiceIndex + 1)}{'.'}</Typography>}
                                                            <Typography style={{whiteSpace: 'pre-line'}}>{choice.text.trim()}</Typography>
                                                        </p>

                                                        <Box style={{marginTop: '1.5rem'}}>
                                                            <Button variant='outlined' color='primary' onClick={() => insert(outputIndex, choiceIndex)} disabled={added}>
                                                                {added ? <CheckCircleIcon/> : <AddCircleOutlineIcon />}
                                                                <span style={{marginLeft: '0.25rem', fontSize: 16}}>{added ? 'Added to document' : 'Add to document'}</span>
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )
                                        })}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}