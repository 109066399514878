import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Grid, Paper, Typography } from '@mui/material';
import type { Document } from '../../api/types/documents';
import { useNavigate } from 'react-router-dom';
import logo from '../auth/wordmuse_logo.svg'
import { UserMenu } from '../layout/UserMenu';

const useStyles = makeStyles((theme: any) => ({
    stretch: { height: '100%' },
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    }
}));

export default function Documents() {
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <Box style={{paddingBottom: 0, backgroundColor: '#ba55d315', minHeight: '100%' }}>
            <Box display='flex' justifyContent='space-between'>
                <img src={logo} height={40} width={150} onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />

                <Box display='flex' justifyContent='flex-end' alignItems='center' padding='1rem' paddingTop='0.75rem'>
                    <UserMenu />
                </Box>
            </Box>

            <Box style={{height: '100%', flex: 1, maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                <Grid className={classes.stretch} container spacing={0}>
                    <Grid className={classes.stretch} item xs={12} md={12} lg={12}>
                        <Box
                            className={classes.stretch}
                            sx={{
                                p: 4,
                                paddingTop: '1.5rem',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                            }}
                            position='relative'
                        >
                            <Typography variant="h5" textAlign='center' style={{marginBottom: '1.5rem'}}><strong>User Guide</strong></Typography>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>What is Wordmuse?</Typography>
                                    <Typography>
                                        Wordmuse is an AI-powered writing assistant that helps creative writers step up their game.
                                        It offers a number of features to overcome writer's block and explore different possibilities
                                        for your work.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>What can Wordmuse do?</Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        There are several ways to use Wordmuse, depending on what you need.
                                    </Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        The writing assistant helps your writing flow and decision making by 
                                        continuing where you left off in the editor.
                                    </Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        Other tools brainstorm ideas for characters, plots, settings, and more.
                                    </Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        If you don't know where to begin, the Story Intro tool will write a few 
                                        introductory paragraphs to your story.
                                    </Typography>
                                    <Typography>
                                        And yet another useful feature is to highlight any text in the editor and 
                                        expand upon the selected text to explore even more directions for your story.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>How can I see text that I've generated in the past?</Typography>
                                    <Typography>
                                        To see a history of your past outputs, open any document and click the History
                                        tab.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>I ran out of words for the month. How do I upgrade my account?</Typography>
                                    <Typography>
                                        To upgrade to a paid plan or change your subcription, click the avatar icon on 
                                        the top right. If you aren't subscribed to a paid plan, you'll see an Upgrade 
                                        Account button. If you are currently subscribed, it will say Manage Account instead.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>How do credits work?</Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        Wordmuse uses a credit system to track usage of the app's underlying AI.
                                        When you sign up for a free or paid trial, you receive a certain number of 
                                        credits, each representing one word of AI-generated text. When you use a 
                                        tool, the number of words generated is subtracted from your remaining credits.
                                    </Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        If you're subscribed to a paid plan, your word credits will refill at the
                                        start of your next billing cycle. Free trial accounts do not receive more
                                        credits.
                                    </Typography>
                                    <Typography>
                                        You can see the number of remaining credits for your account anytime by
                                        clicking the Account avatar on the top right of the app.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>Where can I see how many credits I have?</Typography>
                                    <Typography>
                                        You can see the number of remaining credits for your account anytime by
                                        clicking the Account avatar on the top right of the app.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>Do credits roll over to the next month?</Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        Credits do not rollover to the following month.
                                    </Typography>
                                    <Typography>
                                        However, when upgrading (or downgrading) a plan, any unused credits at 
                                        the time of the subscription change will be added to the total number 
                                        of credits until the next billing cycle, at which point the total will 
                                        be reset to the plan's new word usage limit.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>Can I still access my stuff if I cancel my subscripton?</Typography>
                                    <Typography>
                                        Of course! While you won't be able to use Wordmuse tools anymore, you
                                        will be able to access all your documents and past outputs, as well as
                                        be able to keep writing in the text editor.
                                    </Typography>
                                </Box>
                            </Paper>

                            <Paper style={{padding: '2rem', marginBottom: '1rem'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>Is there a plan to add [new feature]?</Typography>
                                    <Typography style={{marginBottom: '1rem'}}>
                                        If you have feedback for the product, including an idea for a new feature,
                                        feel free to send us your thoughts at <a href="mailto:support@wordmuse.net">support@wordmuse.net</a>.
                                    </Typography>
                                    <Typography>
                                        We love nothing more than to hear from our writers!
                                    </Typography>
                                </Box>
                            </Paper>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}