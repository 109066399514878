import axios from './axios/axios'
import { BASE_URL } from './const/const'

type CreateCheckoutSessionParams = {
    successUrl: string
    cancelUrl: string
    productId: string
}
export const createCheckoutSession = async (params: CreateCheckoutSessionParams) => {
    const body = JSON.stringify(params)

    return await axios.post(`${BASE_URL}stripe-sessions/checkout`, body)
}

type CreatePortalSessionParams = {
    returnUrl: string
}
export const createPortalSession = async (params: CreatePortalSessionParams) => {
    const body = JSON.stringify(params)

    return await axios.post(`${BASE_URL}stripe-sessions/portal`, body)
}