import { Box, Typography } from "@mui/material";
import { ToolInput } from './Elements'

type DescriptionFormProps = {
    error: boolean
    description: string
    handleDescriptionChange: (value: string) => void
}

export default function DescriptionForm({ error, description, handleDescriptionChange }: DescriptionFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Describe</Typography>
                <ToolInput error={!description.length && error} placeholder={'An abandoned bus station'} value={description} onChange={(event) => handleDescriptionChange(event.target.value)} />
            </Box>
        </>
    )
}