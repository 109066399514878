import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CircularProgress, styled } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { differenceWith, isEqual } from 'lodash'
import TextEditor from './TextEditor'
import { fetchDocument, updateDocument } from '../../api/documents';
import type { Document } from '../../api/types/documents'
import { openAiContinue, openAiExpand } from '../../api/openAi';
import Panel from './Panel';
import { withReact } from 'slate-react'
import { withHistory } from 'slate-history'
import {
    Transforms,
    createEditor,
    Node,
    Descendant,
  } from 'slate'
import { withHtml, withLayout } from './hocs';
import { fetchCurrentUser } from '../../api/auth';
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext';
import { OutOfWordsModal } from './OutOfWordsModal';
import ToolSelector from './ToolSelector';
import SomethingWentWrong from '../error/SomethingWentWrong';
import { serialize } from '../../utils/utils';
// @ts-ignore
import gtag from 'ga-gtag'
import { WindowSizeContext, WindowSizeContextValue } from '../../context/WindowSizeContext';

export type ParagraphElement = {
    type: 'paragraph'
    align?: string
    children: Descendant[]
}
  
const useStyles = makeStyles((theme: any) => ({
    stretch: { height: '100%' },
    item: { display: "flex", flexDirection: "column" }
}));
  
const countWords = (text: any[]) => {
    let words = serialize(text).trim().split(/\W/);
    return words.filter(word => word.length > 0).length;
}

const StyledAlert = styled(MuiAlert)`
.MuiAlert-icon {
    margin-left: auto;
}`

export default function Editor() {
    const classes = useStyles();
    const params = useParams();
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext) as CurrentUserContextValue
    const [outOfWordsModalOpen, setOutOfWordsModalOpen] = useState(false)

    const { windowSize } = useContext(WindowSizeContext) as WindowSizeContextValue
    const snapLayout = windowSize.innerWidth < 900
  
    const [data, setData] = useState<Document | null>(null)
    const [content, setContent] = useState<any[] | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>(null)

    const [completionData, setCompletionData] = useState<any>(null)
    const [completionLoading, setCompletionLoading] = useState(false)
    const [completionError, setCompletionError] = useState<any>(null)

    const [saving, setSaving] = useState(false)
    const [saveError, setSaveError] = useState<any>(null)
    const [creatingDocument, setCreatingDocument] = useState(false)

    const [tabIndex, setTabIndex] = useState(0)
    const [toolModalOpen, setToolModalOpen] = useState(false)
    const [tool, setTool] = useState('none')

    // TO DO: Fix type issue
    // @ts-ignore
    const [editor] = useState(() => withLayout(withHtml(withHistory(withReact(createEditor())))))
  
    const wordCount = data ? countWords(data.content) : 0

    const wordsQuotaReached = currentUser && (currentUser.wordsQuota - currentUser.wordsUsed <= 0)

    useEffect(() => {
        if (!!params.id) {
            setLoading(true)
            fetchDocument(params.id)      
                .then(response => {
                    setData(response.data)
                    setContent(response.data.content)
                    setLoading(false)
                })
                .catch(err => {
                    setError(err)
                    setLoading(false)
                })
        }
    }, [])

    useEffect(() => {
        // Document has been loaded
        if (content && data) {
            // TO DO: Make sure interval doesn't already exist
            const interval = setInterval(() => {
                // Need to check for differences in both directions - content added or deleted
                const result1 = differenceWith(content, data.content, isEqual)
                const result2 = differenceWith(data.content, content, isEqual)
                const documentChanged = result1.length > 0 || result2.length > 0

                if (documentChanged) {  
                    // Check if title changed & save new one
                    const titleResult = differenceWith([content[0]], [data.content[0]], isEqual)
                    const titleChanged = titleResult.length > 0
                    let title = data.title

                    if (titleChanged) {
                        title = titleResult[0].children[0].text 
                    }

                    saveDocument(title, content)
                }
            }, 2000);
            return () => clearInterval(interval);
        }
    }, [content, data]);

    // Called before a new document is created
    const clearPreviousDocument = () => {
        setData(null)
    }

    // Called after a new document is created
    const resetEditor = (newDocument: Document) => {
        setData(newDocument)
        setContent(newDocument.content)
        setLoading(false)
        setError(null)
        setCreatingDocument(false)
    }

    const continueWriting = (form: any) => {
        if (!wordsQuotaReached) {
            setTabIndex(snapLayout ? 2 : 1)
            if (data) {
                setCompletionLoading(true)
                setCompletionError(null)
                openAiContinue(data, form, tool)
                    .then(response => {
                        setCompletionData(response.data)
                        setCompletionLoading(false)
                        // Get updated number of words remaining
                        refetchUser()
                    })
                    .catch(err => {
                        setCompletionError(err)
                        setCompletionLoading(false)
                    })
                trackContinueWritingEvent()
            }
        }
    }

    const expandText = (text: string) => {
        if (!wordsQuotaReached) {
            setCompletionLoading(true)
            setCompletionError(null)
            setTabIndex(snapLayout ? 2 : 1)
            if (data) {
                trackExpandTextEvent()
                openAiExpand(text)
                    .then(response => {
                        setCompletionData(response.data)
                        setCompletionLoading(false)
                        // Get updated number of words remaining
                        refetchUser()
                    })
                    .catch(err => {
                        setCompletionError(err)
                        setCompletionLoading(false)
                    })
            }
        }
    }

    const trackExpandTextEvent = () => {
        gtag('event', 'expand_text')
    }

    const trackContinueWritingEvent = () => {
        gtag('event', 'continue_writing')
    }
    
    const refetchUser = () => {
        fetchCurrentUser()
            .then(response => {
                setCurrentUser(response.data)
                checkIfWordsQuotaReached(response.data.wordsQuota - response.data.wordsUsed)
            })
            .catch(err => {
                // TO DO
                console.log(err)
            })
    }

    const checkIfWordsQuotaReached = (numWords: number) => {
        if (numWords <= 0) {
            setOutOfWordsModalOpen(true)
        }
    }
  
    // TO DO
    const openAiFetchPrompt = async () => {}
    
    const handleContentChange = (newValue: any) => {
        // onChange gets triggered when cursor is focused
        if (data) {
            const result = differenceWith(content, newValue, isEqual)
            if (result.length > 0) {
                setSaving(true)
            }
        }

        setContent(newValue)
    }

    const saveDocument = async (title: string, content: any[]) => {
        if (data) {
            setSaveError(null)
            updateDocument({
                ...data,
                title,
                content
            })
                .then(response => {
                    setData(response.data)
                    setSaving(false)
                })
                .catch(err => {
                    setSaveError(err)
                })
        }
    }

    const insertIntoDocument = (text: string) => {   
        const paragraphs = text
            .split('\n\n')
            .join('\n')
            .split('\n')
            .map(paragraph => paragraph.trim())
            .filter(paragraph => paragraph.length > 0)
            .map(paragraph => ({ type: 'paragraph', children: [{ text: paragraph }] }))

        const lastNodeIndex = editor.children.length - 1
        const lastNode = editor.children[lastNodeIndex]
        const lastNodeIsEmpty = lastNode.children[0].text != undefined && lastNode.children[0].text.length === 0

        if (lastNodeIsEmpty) {
            Transforms.removeNodes(
                editor,
                { at: [lastNodeIndex] }
            )
        }

        Transforms.insertNodes(
            editor,
            paragraphs,
            { at: [lastNodeIsEmpty ? lastNodeIndex : editor.children.length] }
        )
    }

    if (error) {
        return <SomethingWentWrong />
    }

    if (loading || !data || !data.content) {
        return <CircularProgress color='secondary' style={{position: 'absolute', top: 'calc(50% - 40px)', left: '50%'}} />
    }

    const panelProps = { continueWriting, completionLoading, openToolModal: () => setToolModalOpen(true), currentTool: tool }

    return (
        <Box style={{height: '100%', marginBottom: 0, paddingLeft: 0, paddingRight: 0}}>
            {saveError && <StyledAlert severity='error' onClose={() => setError(null)} style={{display: 'flex', justifyContent: 'center'}}>Failed to save document.</StyledAlert>}

            <Grid 
                className={classes.stretch} 
                container 
                spacing={0}
            >
                {!snapLayout && (
                    <Grid item xs={12} md={3} lg={3} style={{borderRight: '3px solid #eee', height: '100vh' }}>
                        <Panel {...panelProps} />
                    </Grid>
                )}

                <Grid item xs={12} md={9} lg={9} style={{maxHeight: '100%'}}>
                    <TextEditor 
                        // To render panel inside a tab when window size is smaller than snap size
                        {...panelProps}
                        tabIndex={tabIndex} 
                        setTabIndex={setTabIndex} 
                        onChange={handleContentChange} 
                        document={data} 
                        editor={editor} 
                        saving={saving} 
                        resetEditor={resetEditor} 
                        clearPreviousDocument={clearPreviousDocument}
                        completionData={completionData} 
                        completionLoading={completionLoading}
                        completionError={completionError}
                        insertIntoDocument={insertIntoDocument}
                        // insertOldOutputIntoDocument={insertOldOutputIntoDocument}
                        currentTool={tool}
                        expandText={expandText}
                    />
                </Grid>

                <OutOfWordsModal open={outOfWordsModalOpen} close={() => setOutOfWordsModalOpen(false)} />

                <ToolSelector open={toolModalOpen} close={() => setToolModalOpen(false)} currentTool={tool} setTool={setTool} />
            </Grid>
        </Box>
    )
}