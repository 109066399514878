// TO DO: Add TS
// @ts-nocheck

import React, { Ref, PropsWithChildren, useContext } from 'react'
import ReactDOM from 'react-dom'
import { cx, css } from '@emotion/css'
import { FormatAlignCenter, FormatAlignJustify, FormatAlignLeft, FormatAlignRight, FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, FormatUnderlined, LooksOne, LooksTwo } from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Button as MuiButton } from '@mui/material';
import { useSlate } from 'slate-react'
import {
  Editor,
  Transforms,
  Element as SlateElement
} from 'slate'
import { formatNumber } from '../../utils/utils';
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext';
import { WindowSizeContext, WindowSizeContextValue } from '../../context/WindowSizeContext';

interface BaseProps {
  className: string
  [key: string]: unknown
}
type OrNull<T> = T | null

const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean
        reversed: boolean
      } & BaseProps
    >,
    ref: Ref<OrNull<HTMLSpanElement>>
  ) => (
    <span
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          cursor: pointer;
          color: ${reversed
            ? active
              ? 'white'
              : '#aaa'
            : active
            ? 'black'
            : '#ccc'};
        `
      )}
    />
  )
)

export const EditorValue = React.forwardRef(
  (
    {
      className,
      value,
      ...props
    }: PropsWithChildren<
      {
        value: any
      } & BaseProps
    >,
    ref: Ref<OrNull<null>>
  ) => {
    const textLines = value.document.nodes
      .map(node => node.text)
      .toArray()
      .join('\n')
    return (
      <div
        ref={ref}
        {...props}
        className={cx(
          className,
          css`
            margin: 30px -20px 0;
          `
        )}
      >
        <div
          className={css`
            font-size: 14px;
            padding: 5px 20px;
            color: #404040;
            border-top: 2px solid #eeeeee;
            background: #f8f8f8;
          `}
        >
          Slate's value as text
        </div>
        <div
          className={css`
            color: #404040;
            font: 12px monospace;
            white-space: pre-wrap;
            padding: 10px 20px;
            div {
              margin: 0 0 0.5em;
            }
          `}
        >
          {textLines}
        </div>
      </div>
    )
  }
)

export const Instruction = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          white-space: pre-wrap;
          margin: 0 -20px 10px;
          padding: 10px 20px;
          font-size: 14px;
          background: #f8f8e8;
        `
      )}
    />
  )
)

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <div
      {...props}
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }
          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
  )
)

export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null
}

export const Toolbar = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<OrNull<HTMLDivElement>>
  ) => (
    <Menu
      {...props}
      ref={ref}
      className={cx(
        className,
      //   css`
      //     position: relative;
      //     padding: 1px 18px 4px;
      //     border-bottom: 2px solid #eee;
      // `
        css`
          position: relative;
          padding: 1px 18px 4px;
          border-bottom: 2px solid #eee;
        `
      )}
    />
  )
)

export function EditorToolbar({ wordCount, saving, hideAlignmentButtons, completionLoading, continueWriting }) {
  const { currentUser } = useContext(CurrentUserContext) as CurrentUserContextValue
  const { windowSize } = useContext(WindowSizeContext) as WindowSizeContextValue
  const snapLayout = windowSize.innerWidth < 900
  const tabIconsOnly = windowSize.innerWidth < 700

  const outputCallsDisabled = currentUser ? (currentUser.wordsQuota - currentUser.wordsUsed <= 0): true

  return (
    <Box style={{backgroundColor: '#fff'}}>
      <Toolbar style={{backgroundColor: '#fff', paddingLeft: snapLayout ? '1.75rem' : '1.25rem', padding: '1rem 0 0.75rem 1.25rem', width: '100%', display: 'flex', justifyContent: snapLayout ? 'space-between' : 'flex-start', alignItems: 'center'}}>
        <Box style={{height: '1.5rem'}}>
          <Box display='inline-block' style={{marginRight: '0.75rem'}}>
            <MarkButton format="bold" icon="format_bold" />
          </Box>
          <Box display='inline-block' style={{marginRight: '0.75rem'}}>
            <MarkButton format="italic" icon="format_italic" />
          </Box>
          <Box display='inline-block' style={{marginRight: '0.75rem'}}>
            <MarkButton format="underline" icon="format_underlined" />
          </Box>
          {/* <Box display='inline-block' style={{marginRight: '0.75rem'}}>
            <BlockButton format="heading-one" icon="looks_one" />
          </Box>
          <Box display='inline-block' style={{marginRight: '0.75rem'}}>
            <BlockButton format="heading-two" icon="looks_two" />
          </Box> */}
          {!hideAlignmentButtons && (
            <>
              <Box display='inline-block' style={{marginRight: '0.75rem'}}>
                <BlockButton format="left" icon="format_align_left" />
              </Box>
              <Box display='inline-block' style={{marginRight: '0.75rem'}}>
                <BlockButton format="center" icon="format_align_center" />
              </Box>
              <Box display='inline-block' style={{marginRight: '0.75rem'}}>
                <BlockButton format="right" icon="format_align_right" />
              </Box>
              <Box display='inline-block'>
                <BlockButton format="justify" icon="format_align_justify" />
              </Box>
            </>
          )}
        </Box>

        {/* <Box style={{display: 'flex', alignItems: 'center'}}>
          <div style={{marginLeft: '2rem'}}>Words: <strong>{formatNumber(wordCount)}</strong></div>
          <div style={{marginLeft: '2rem', display: 'flex', alignContent: 'center' }}>
            {saving ? 'Saving' : 'Saved'}
            {saving ? <CachedIcon style={{marginLeft: '0.25rem'}} /> : <DoneIcon color='success' style={{marginLeft: '0.25rem'}} />}
          </div>
        </Box> */}

        {snapLayout && (
          <Box width={tabIconsOnly ? 200 : 300} marginRight={'1.25rem'}>
            <MuiButton fullWidth variant='contained' color='secondary' disabled={outputCallsDisabled || completionLoading} onClick={() => continueWriting({ tone: '' })}>
              {outputCallsDisabled ? 'Word limit reached' : 'Write'}
            </MuiButton> 
          </Box>
        )}
        
      </Toolbar>
    </Box>
  )
}

const renderToolbarIcon = (icon: string) => {
  switch(icon) {
      case 'format_bold':
          return <FormatBold />
      case 'format_italic':
          return <FormatItalic />
      case 'format_underlined':
          return <FormatUnderlined />
      case 'looks_one':
          return <LooksOne />
      case 'looks_two':
          return <LooksTwo />
      case 'format_quote':
          return <FormatQuote />
      case 'format_list_numbered':
          return <FormatListNumbered />
      case 'format_list_bulleted':
          return <FormatListBulleted />
      case 'format_align_left':
          return <FormatAlignLeft />
      case 'format_align_center':
          return <FormatAlignCenter />
      case 'format_align_right':
          return <FormatAlignRight />
      case 'format_align_justify':
          return <FormatAlignJustify />
      default:
          return <FormatBold />
  }
}

const MarkButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={event => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {renderToolbarIcon(icon)}
    </Button>
  )
}

const BlockButton = ({ format, icon }) => {
  const editor = useSlate()
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={event => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {renderToolbarIcon(icon)}
    </Button>
  )
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  )
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  })
  let newProperties: Partial<SlateElement>
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    }
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}
