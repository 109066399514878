import axios from 'axios'
import { BASE_URL, getHeaders } from '../const/const'
import { getCookie, removeCookie, setCookie } from '../utils/utils'
import history from '../../routes/history'

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
})

axiosInstance.interceptors.request.use(
    (config) => {
        if (config && config.headers) {
            const accessToken = getCookie().accessToken
            config.headers['Authorization'] = `Bearer ${accessToken}`
            return config;
        }
    },
    (error) => {
      return Promise.reject(error);
    });

const responseIntercept = axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (error) => {
        const prevRequest = error.config;
        if (error.response) {
            if (error.response.status === 401 && !prevRequest._retry) {
                prevRequest._retry = true;
                
                try {
                    const { data } = await refresh()
                    const tokens = getCookie()
                    prevRequest.headers['Authorization'] = `Bearer ${data.accessToken}`
                    setCookie({ ...tokens, accessToken: data.accessToken })
                    return axiosInstance(prevRequest);
                } catch (err) {
                    // @ts-ignore
                    if (err.response.status === 401) {
                        removeCookie()
                        history.push('/')
                    }
                }
            }
        }
      return Promise.reject(error);
    }
);

// TO DO: Where to do this? It currently breaks the interceptor functionality
// axiosInstance.interceptors.response.eject(responseIntercept)

const refresh = () => {
    const token = getCookie().refreshToken
    return axios.post(`${BASE_URL}auth/refresh`, {}, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
}

export default axiosInstance