import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Cookie from 'js-cookie'
import { forgotPassword, login } from '../../api/auth';
import logo from './wordmuse_logo.svg'
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

const theme = createTheme();

export default function ForgotPassword() {
  const navigate = useNavigate()
  const [message, setMessage] = useState<string | null>(null)
  const [emptyEmail, setEmptyEmail] = useState(false)
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState(false)

  const handleEmailChange = (value: string) => {
    if (value.length === 0) {
      setEmptyEmail(true)
    } else {
      setEmptyEmail(false)
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email')
    
    if (!email) {
      setEmptyEmail(true)
      return
    }

    setLoading(true)
    setError(null)
    forgotPassword(data)
      .then(response => {
          setMessage(response.data)
          setLoading(false)
      })
      .catch(err => {
          setError(err)
          setLoading(false)
      })
};

  return (
    <ThemeProvider theme={theme}>
      <img src={logo} height={40} width={150}  onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {error && <MuiAlert severity='error' onClose={() => setError(null)}>{error?.response?.data?.message}</MuiAlert>}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            Recover Password
          </Typography>

          {message && (
            <Box marginTop='0.5rem' display='flex' flexDirection='column' justifyContent='center'>
              <Typography style={{marginBottom: '1rem'}}>Password recovery email has been sent.</Typography>
              <Grid container style={{display: 'flex', justifyContent: 'center'}}>
                <Grid item>
                  <Link onClick={() => navigate('/signin')} variant="body2" style={{ cursor: 'pointer' }}>
                    {"Sign in"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}

          {!message && (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(event) => handleEmailChange(event.target.value)}
                error={emptyEmail}
                helperText={emptyEmail ? 'Email cannot be blank' : ''}
              />
              <LoadingButton
                loading={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={emptyEmail}
              >
                Send Password Reset Email
              </LoadingButton>
              <Grid container>
                <Grid item>
                  <Link onClick={() => navigate('/signin')} variant="body2" style={{ cursor: 'pointer' }}>
                    {"Sign in"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          )}

        </Box>
      </Container>
    </ThemeProvider>
  );
}