import * as React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useContext, useState } from 'react';
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import { Box, IconButton, Divider, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Paper } from '@mui/material';
import PaidIcon from '@mui/icons-material/Paid';
import InfoIcon from '@mui/icons-material/Info';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LinearProgress from '@mui/material/LinearProgress';
import { capitalize, formatNumber } from '../../utils/utils';
import { removeCookie } from '../../api/utils/utils';
import { logout } from '../../api/auth';
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { createCheckoutSession, createPortalSession } from '../../api/stripe';
import { Plan } from '../../api/types/auth';
import { WORDS } from '../../api/const/const'
// @ts-ignore
import gtag from 'ga-gtag'

let stripePromise: Promise<Stripe | null>;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PRODUCTION! : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_DEVELOPMENT!);
  }
  return stripePromise;
};

export function UserMenu () {
  // TO DO: Make useCurrentUser hook
  const { currentUser, setCurrentUser, setAuthenticated } = useContext(CurrentUserContext) as CurrentUserContextValue
  const location = useLocation()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const wordsTotal = formatNumber(currentUser?.wordsQuota || 0)
  const wordsUsed = formatNumber(currentUser?.wordsUsed || 0)
  const percentageOfQuotaUsed = currentUser ? Math.round(((currentUser.wordsUsed / currentUser.wordsQuota) * 100)) : 100
  const planWordQuota = WORDS[currentUser?.plan || Plan.FREE]
  const areWordsRemainingBeforePlanChange = currentUser ? (currentUser.wordsQuota > planWordQuota) : false

  // TO DO: Testing
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false) 

  const handleClick = (event: { currentTarget: React.SetStateAction<HTMLElement | null>; }) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserGuideButtonClick = () => {
    handleClose()
    navigate('/user-guide')
  }

  const handleUpgradeAccountButtonClick = () => {
    handleClose()
    trackUpgradeAccountButtonClickEvent()
    // TO DO: Testing
    setUpgradeModalOpen(true)
    redirectToCustomerPortal()
  }

  const handleManageAccountButtonClick = () => {
    handleClose()
    // TO DO: Testing
    redirectToCustomerPortal()
  }

  const handleLogoutButtonClick = () => {
    handleClose()
    logout()
      .finally(() => {
        removeCookie()
        setAuthenticated(false)
        setCurrentUser(null)
      })
  }

  const redirectToCustomerPortal = async () => {
    if (currentUser?.plan !== Plan.FREE) {
      // setLoading(true)
      const returnUrl = `${window.location.origin}${location.pathname}`
      createPortalSession({ returnUrl })
        .then((res) => {
          const { url } = res.data
          window.location.href = url
        })
        .catch(err => {
          // setLoading(false)
          console.log(err)
        })
    }
  }

  const trackUpgradeAccountButtonClickEvent = () => {
    gtag('event', 'upgrade_button_click')
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          style={{marginLeft: 'auto', marginRight: 'auto'}}
        >
          <Avatar sx={{ width: 32, height: 32 }} />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            // width: 175,
            // width: 200,
            width: 250,
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: -1,
            ml: -0.75,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorPosition={{ top: 0, left: 0 }}
      >
        <Box display='flex' alignContent='center' padding='0.5rem 1rem 0.75rem 1rem'>
          <AccountCircleIcon style={{ marginRight: '0.5rem' }} />
          <Box fontSize={14} fontWeight='bold' textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>{currentUser?.email}</Box>
        </Box>

        <Divider />

        <Box paddingLeft='1rem' paddingRight='1rem' paddingBottom='0.5rem' paddingTop='1rem'>
          <Box display='flex' justifyContent='center' alignItems='center' paddingRight={'0.75rem'} marginBottom='0.5rem'>
            <CardMembershipIcon color='secondary' fontSize="medium" style={{ marginRight: '0.5rem'}} />
            <Typography color='secondary' fontSize={16} fontWeight='bold' style={{textAlign: 'center'}}>{capitalize(currentUser?.plan || '')}</Typography>
          </Box>
          
          <Typography fontSize={14} style={{marginBottom: '0.25rem', textAlign: 'center'}}>{`${wordsUsed} / ${wordsTotal}${areWordsRemainingBeforePlanChange ? '*' : ''} words`}</Typography>
          <LinearProgress color='secondary' variant="determinate" value={percentageOfQuotaUsed} />

          {areWordsRemainingBeforePlanChange && <Typography fontSize={12} style={{ marginTop: '0.5rem' }}>* Includes any unused words when changing your plan until the next billing cycle.</Typography>}
        </Box>

        <Box style={{paddingBottom: '0.5rem', paddingTop: '0.5rem'}}>
          {currentUser?.plan === Plan.FREE && (
            <MenuItem style={{fontSize: 16, paddingTop: '0.5rem', paddingBottom: '0.5rem'}} onClick={handleUpgradeAccountButtonClick}>
              <ListItemIcon>
                <PaidIcon fontSize="medium" />
              </ListItemIcon>
              Upgrade Account
            </MenuItem>
          )}
          {currentUser?.plan !== Plan.FREE && (
            <MenuItem style={{fontSize: 16, paddingTop: '0.5rem', paddingBottom: '0.5rem'}} onClick={handleManageAccountButtonClick}>
              <ListItemIcon>
                <PaidIcon fontSize="medium" />
              </ListItemIcon>
              Manage Account
            </MenuItem>
          )}
            <MenuItem style={{fontSize: 16, paddingTop: '0.5rem', paddingBottom: '0.5rem'}} onClick={handleUserGuideButtonClick}>
              <ListItemIcon>
                <InfoIcon fontSize="medium" />
              </ListItemIcon>
              User Guide
            </MenuItem>
          <MenuItem style={{fontSize: 16, paddingTop: '0.5rem', paddingBottom: '0.5rem'}} onClick={handleLogoutButtonClick}>
            <ListItemIcon>
              <Logout fontSize="medium" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Box>
      </Menu>

      <CheckoutModal isOpen={upgradeModalOpen} close={() => setUpgradeModalOpen(false)} />

    </React.Fragment>
  )
};

export function CheckoutModal({ isOpen, close }: { isOpen: boolean, close: () => void }) {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [productId, setProductId] = useState('novel')
  const { currentUser } = useContext(CurrentUserContext) as CurrentUserContextValue

  const sessionOptions = {
    productId,
    successUrl: `${window.location.origin}${location.pathname}`,
    cancelUrl: `${window.location.origin}${location.pathname}`
  }

  const redirectToCheckout = async () => {
    if (currentUser?.plan === Plan.FREE) {
      setLoading(true)
      trackCheckoutRedirectEvent()
      const stripe = await getStripe()
      createCheckoutSession(sessionOptions)
        .then((res) => {
          stripe?.redirectToCheckout({ sessionId: res.data.sessionId })
            .then(() => setLoading(false))
            .catch(err => setLoading(false))
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const trackCheckoutRedirectEvent = () => {
    gtag('event', 'checkout_redirect')
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Upgrade Your Account
      </DialogTitle>
      <DialogContent style={{width: 300}}>

        <Typography fontSize={16} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Choose a Plan</Typography>
       
        <Paper style={{ cursor: 'pointer', marginBottom: '1rem', padding: '1rem', backgroundColor: productId === Plan.HOBBY ? '#ba55d315' : '#eee', outline: productId === Plan.HOBBY ? '2px solid purple' : 'none'}} onClick={() => setProductId(Plan.HOBBY)}>
          <DialogContentText id="alert-dialog-description">
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Hobby</Typography>
              <Typography fontSize={14}>$9</Typography>
            </Box>
            <Typography>10,000 words / mo.</Typography>
          </DialogContentText>
        </Paper>

        <Paper style={{ cursor: 'pointer', marginBottom: '1rem', padding: '1rem', backgroundColor: productId === Plan.NOVEL ? '#ba55d315' : '#eee',  outline: productId === Plan.NOVEL ? '2px solid purple' : 'none'}} onClick={() => setProductId(Plan.NOVEL)}>
          <DialogContentText id="alert-dialog-description">
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Novel</Typography>
              <Typography fontSize={14}>$29</Typography>
            </Box>
            <Typography>50,000 words / mo.</Typography>
          </DialogContentText>
        </Paper>

        <Paper style={{ cursor: 'pointer', padding: '1rem', backgroundColor: productId === Plan.MASTERPIECE ? '#ba55d315' : '#eee', outline: productId === Plan.MASTERPIECE ? '2px solid purple' : 'none'}} onClick={() => setProductId(Plan.MASTERPIECE)}>
          <DialogContentText id="alert-dialog-description">
            <Box display='flex' justifyContent='space-between'>
              <Typography fontWeight='bold'>Masterpiece</Typography>
              <Typography fontSize={14}>$49</Typography>
            </Box>
            <Typography>100,000 words / mo.</Typography>
          </DialogContentText>
        </Paper>

        <Typography fontSize={14} style={{color: 'rgb(0,0,0,0.7)', marginTop: '1rem'}}>Note: Unused words do not carry over to the next month.</Typography>

      </DialogContent>
      <DialogActions>
        <Button onClick={close} disabled={loading}>Cancel</Button>
        <Button variant='contained' onClick={redirectToCheckout} disabled={loading}>Continue</Button>
      </DialogActions>
    </Dialog>
  )
}