import { Box, Typography } from "@mui/material";
import image from './images/ship.png'
import logo from '../auth/wordmuse_logo.svg'
import { useNavigate } from "react-router-dom";

type SomethingWentWrongProps = {
    showLogo?: boolean
}

export default function SomethingWentWrong({ showLogo }: SomethingWentWrongProps) {
    const navigate = useNavigate()
    
    return (
        <>
            {showLogo && <img src={logo} height={40} width={150}  onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />}
            
            <Box display='flex' flexDirection='column' alignItems='center' marginTop={showLogo ? 0 : '40px'}>
                <Typography color='secondary' variant="h2">Something went wrong</Typography>
                <img src={image} style={{ height: 500, width: 500, flexGrow: 1, marginTop: '1rem', marginBottom: '2rem' }} />
                <Typography color='primary'>Please refresh the page or try again in a few seconds.</Typography>
            </Box>
        </>
    )
}