// TO DO: Add TS
// @ts-nocheck

import { useCallback, useContext, useState } from 'react'
import isHotkey from 'is-hotkey'
import { Editable, Slate } from 'slate-react'
import {
  Editor,
  Node,
  Descendant,
  Range
} from 'slate'
import { useNavigate } from 'react-router-dom';
import { EditorToolbar, toggleMark } from './TextEditorComponents'
import { Avatar, Box, Button as MuiButton, IconButton, styled, Tab, Tabs, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import WestIcon from '@mui/icons-material/West';
import DescriptionIcon from '@mui/icons-material/Description';
import BuildIcon from '@mui/icons-material/Build';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import HistoryIcon from '@mui/icons-material/History';
import { DeleteModal } from './DeleteModal';
import { CreateModal } from './CreateModal';
import { Output } from './Output'
import { History } from './History'
import HoveringToolbar from './HoveringToolbar'
import { serialize } from '../../utils/utils'
import { UserMenu } from '../layout/UserMenu'
import { WindowSizeContext, WindowSizeContextValue } from '../../context/WindowSizeContext';
import Panel from './Panel'

export type ParagraphElement = {
  type: 'paragraph'
  align?: string
  children: Descendant[]
}

export type TitleElement = { type: 'heading-one'; children: Descendant[] }

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline'
}

const countWords = (text) => {
    let words = serialize(text).trim().split(/\W/);
    return words.filter(word => word.length > 0).length;
}

export default function TextEditor ({ 
  onChange, 
  currentTool, 
  document, 
  editor, 
  resetEditor, 
  saving, 
  clearPreviousDocument, 
  tabIndex, 
  setTabIndex, 
  completionData, 
  completionLoading, 
  completionError, 
  insertIntoDocument, 
  expandText,
  // Panel props
  continueWriting,
  openToolModal
}) {
  const navigate = useNavigate()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const { windowSize } = useContext(WindowSizeContext) as WindowSizeContextValue
  const snapLayout = windowSize.innerWidth < 900
  const tabIconsOnly = windowSize.innerWidth < 700
  const hideToolbar = windowSize.innerWidth < 550
  const mobile = windowSize.innerWidth < 500

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleOpenCreateModal = () => {
    setCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setCreateModalOpen(false);
  };

  const editorTab = (
    <Box>
      {/* {!hideToolbar && <EditorToolbar saving={saving} wordCount={countWords(document.content)} />} */}
      <EditorToolbar saving={saving} wordCount={countWords(document.content)} hideAlignmentButtons={hideToolbar} completionLoading={completionLoading} continueWriting={continueWriting} />

      <HoveringToolbar expandText={expandText} />

      <Box style={{fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif'}}>
        <Editable
          style={{ 
            height: 'calc(100vh - 114px)', 
            overflowY: 'scroll', 
            padding: tabIconsOnly ? '0 2rem' : '2rem 6rem'
          }}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          spellCheck
          autoFocus
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
              }
            }
          }}
          renderPlaceholder={(props) => {
            const { document } = props.editor.value
            if (document.text !== '' || document.nodes.size !== 1) return false
            return (
              <span
                contentEditable={false}
                styles={{ pointerEvents: 'none', userSelect: 'none' }}
              >
                {editor.props.placeholder}
              </span>
            )
          }}
          decorate={([node, path]) => {
            if (editor.selection != null) {
              if (
                !Editor.isEditor(node) &&
                Editor.string(editor, [path[0]]) === "" &&
                Range.includes(editor.selection, path) &&
                Range.isCollapsed(editor.selection) &&
                // Only show placeholder for first or second element
                path[0] < 2
              ) {
                return [
                  {
                    ...editor.selection,
                    placeholder: true,
                  },
                ];
              }
            }
            return [];
          }}
        />
      </Box>
    </Box>
  )

  // Tabs need to be shifted down when layout snaps
  let tab
  switch (tabIndex) {
    case 0:
      tab = editorTab
      break
    case 1:
      if (snapLayout) {
        tab = <Panel
          continueWriting={continueWriting}
          completionLoading={completionLoading}
          openToolModal={openToolModal}
          currentTool={currentTool}
        />
      } else {
        tab = <Output currentTool={currentTool} data={completionData} loading={completionLoading} error={completionError} insertIntoDocument={insertIntoDocument} />
      }
      break
    case 2:
      if (snapLayout) {
        tab = <Output currentTool={currentTool} data={completionData} loading={completionLoading} error={completionError} insertIntoDocument={insertIntoDocument} />
      } else {
        tab = <History insertIntoDocument={insertIntoDocument} />
      }
      break
    case 3:
      tab = <History insertIntoDocument={insertIntoDocument} />
      break      
    default:
      tab = editorTab
  }

  let tabMinWidth = 90
  if (hideToolbar) {
    tabMinWidth = 55
  }
  if (mobile) {
    tabMinWidth = 50
  }

  return (
    <Box style={{backgroundColor: '#fff', height: '100%'}}>
      <Slate editor={editor} value={document.content} onChange={onChange}>

        <Box style={{backgroundColor: '#fff', paddingLeft: '1.5rem', display: 'flex', justifyContent: 'space-between', borderBottom: hideToolbar ? '1px solid #ccc' : 'none' }}>
          {snapLayout && (
            <Box style={{marginBottom: '0.5rem', marginTop: '0.25rem', marginRight: '0.5rem'}}>
              <IconButton onClick={() => navigate('/documents')}>
                <WestIcon />
              </IconButton>
            </Box>
          )}

          <Tabs indicatorColor='primary' textColor='primary' value={tabIndex} onChange={(event, value) => { setTabIndex(value) }} style={{height: mobile ? 50 : 60}}>
            <Tab icon={<DescriptionIcon />} iconPosition='start' label={tabIconsOnly ? '' : 'Editor'} style={{padding: tabIconsOnly ? '0.75rem 0' : '0 1rem 0.5rem 1rem', minWidth: tabMinWidth }} />
            {snapLayout && <Tab icon={<BuildIcon />} iconPosition='start' label={tabIconsOnly ? '' : 'Tool'} style={{padding: tabIconsOnly ? '0.75rem 0' : '0 1rem 0.5rem 1rem', minWidth: tabMinWidth}} />}
            <Tab icon={<SmartToyIcon />} iconPosition='start' label={tabIconsOnly ? '' : 'Output'} style={{padding: tabIconsOnly ? '0.75rem 0' : '0 1rem 0.5rem 1rem', minWidth: tabMinWidth}} />
            <Tab icon={<HistoryIcon />} iconPosition='start' label={tabIconsOnly ? '' : 'History'} style={{padding: tabIconsOnly ? '0.75rem 0' : '0 1rem 0.5rem 1rem', minWidth: tabMinWidth}} />
          </Tabs>

          <Box style={{marginLeft: 'auto', paddingTop: tabIconsOnly ? 0 : '0.5rem', paddingRight: '1rem', display: 'flex', alignItems: 'center'}}>
              <Box style={{marginRight: '0.5rem'}}>
                <IconButton onClick={handleOpenDeleteModal} style={{ flexGrow: 0 }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
              {!snapLayout && (
                <Box style={{marginRight: '1rem'}}>
                  <MuiButton onClick={handleOpenCreateModal} color='primary' variant='contained'>New</MuiButton>
                </Box>
              )}
              <UserMenu />
            </Box>
        </Box>

        {tab}

        <CreateModal close={handleCloseCreateModal} open={createModalOpen} resetEditor={resetEditor} clearPreviousDocument={clearPreviousDocument} />

        <DeleteModal close={handleCloseDeleteModal} document={document} open={deleteModalOpen} />
        
      </Slate>
    </Box>
  )
}

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align }
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      )
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      )
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      )
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      )
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      )
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      )
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      )
  }
}

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  if (leaf.placeholder) {
    return (
      <div style={{position: 'relative'}}>
        <span {...attributes}>{children}</span>
        <span
          style={{ opacity: 0.3, position: "absolute", top: 0 }}
          contentEditable={false}
        >
          {children.props.parent && children.props.parent.type === 'heading-one' ? 'Title' : 'The dreaded blank page...'}
        </span>
      </div>
    );
  }

  return <span {...attributes}>{children}</span>
}