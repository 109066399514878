import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import ScienceIcon from '@mui/icons-material/Science';
import character_backstory from './images/character_backstory.png'
import character_list from './images/character_list.png'
import story_concept from './images/story_concept.png'
import outline_builder from './images/outline_builder.png'
import title from './images/title.png'
import worldbuilding from './images/worldbuilding.png'
import essay_outline from './images/essay_outline.png'
import description from './images/description.png'
import intro from './images/intro.png'
import { useContext, useState } from "react";
// @ts-ignore
import gtag from 'ga-gtag'
import { WindowSizeContext, WindowSizeContextValue } from "../../context/WindowSizeContext";

type ToolSelectorProps = {
    open: boolean
    close: () => void
    currentTool: string
    setTool: (value: string) => void
}

export default function ToolSelector({ currentTool, open, close, setTool }: ToolSelectorProps) {
  const { windowSize } = useContext(WindowSizeContext) as WindowSizeContextValue
  const snapLayout = windowSize.innerWidth < 900
  const tabIconsOnly = windowSize.innerWidth < 700
  const hideToolbar = windowSize.innerWidth < 550
  const mobile = windowSize.innerWidth < 500

  const [selectedTool, setSelectedTool] = useState(currentTool)

    // TO DO: Move to const file
    const tools = [
        { name: 'outline-builder', title: 'Plot Outline', description: 'Build an outline for your story', image: outline_builder },
        { name: 'concept', title: 'Story Concept', description: 'Find a concept for your new story', image: story_concept},
        { name: 'character-list', title: 'Character List', description: 'Find interesting characters for your story', image: character_list},
        { name: 'character-backstory', title: 'Backstory', description: 'Learn about your character\'s past', image: character_backstory},
        { name: 'worldbuilding', title: 'Worldbuilding', description: 'Explore the world where your story takes place', image: worldbuilding},
        { name: 'description', title: 'Description', description: 'Describe a person, place, or thing', image: description},
        { name: 'intro', title: 'Story Intro', description: 'Kick off your story with an intro paragraph', image: intro},
        // { name: 'story-title', title: 'Title', description: 'Find a great title for your story', image: title},
        { name: 'essay-outline', title: 'Essay Outline', description: 'Generate an essay outline for any topic', image: essay_outline},
    ]

    const confirmSelectedTool = () => {
      setTool(selectedTool)
      trackToolSelectEvent()
      close()
    }

    const trackToolSelectEvent = () => {
      gtag('event', 'tool_select', {
        tool: selectedTool,
      })
    }

    let cardWidth = 'calc(20% - 10px)'
    if (snapLayout) {
      cardWidth = 'calc(25% - 10px)'
    }
    if (tabIconsOnly) {
      cardWidth = 'calc(33% - 10px)'
    }
    // TO DO: Some words break before this breakpoint
    if (hideToolbar) {
      cardWidth = 'calc(50% - 10px)'
    }

    return (
            <Dialog
                open={open}
                onClose={close}
                fullWidth
                maxWidth='lg'
                
            >
            <Box display='flex' justifyContent='space-between'>
                <DialogTitle fontWeight='bold'>
                  Brainstorming Tools{' '}
                  <Tooltip title={"Experimental"} placement='right'>
                    <ScienceIcon fontSize='small' />
                  </Tooltip>
                </DialogTitle>
                <IconButton onClick={close} style={{ height: 40, marginLeft: 'auto', marginTop: '0.5rem', marginRight: '0.5rem' }}><CloseIcon /></IconButton>
            </Box>

            <DialogContent style={{paddingTop: 0}}>
                <Box display='flex' flexWrap='wrap'>
                    {tools.map((tool, index) => {
                        return <ActionAreaCard selected={tool.name === selectedTool} key={index} style={{ flexBasis: cardWidth, margin: 5 }} title={tool.title} description={tool.description} image={tool.image} onClick={() => setSelectedTool(tool.name)} />
                    })}
                    <DefaultCard selected={selectedTool === 'none'} onClick={() => setSelectedTool('none')} cardWidth={cardWidth} />
                </Box>
            </DialogContent>


            <DialogActions style={{ display: 'flex', justifyContent: 'center'}}>
                <Button variant='contained' color='info' onClick={confirmSelectedTool} autoFocus style={{width: 400, marginBottom: '0.5rem', marginRight: '0.5rem'}}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>

    )
}

function ActionAreaCard({ key, selected, image, title, description, onClick, style }: { key: number, selected: boolean, image?: string, title: string, description: string, onClick: () => void, style: any }) {
    return (
        <Card key={key} sx={{ maxWidth: 345 }} onClick={onClick} style={{ ...style, outline: selected ? '3px solid purple' : 'none' }}>
          <CardActionArea style={{minHeight: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', paddingBottom: '1rem' }}>
              <CardMedia
                className='tool-selector-image'
                component="img"
                image={image}
                style={{maxHeight: '100px'}}
              />
              <CardContent>
                <Typography fontWeight='bold' gutterBottom component="div" style={{ wordBreak: 'break-word'}}>
                  {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              </CardContent>
          </CardActionArea>
        </Card>
    );
}

function DefaultCard({ selected, onClick, cardWidth }: { selected: boolean, onClick: () => void, cardWidth: string }) {
    return (
        <Card sx={{ maxWidth: 345 }} onClick={onClick} style={{ flexBasis: cardWidth, margin: 5, outline: selected ? '3px solid purple' : 'none' }}>
          <CardActionArea style={{ minHeight: '100%' }}>
              <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography fontWeight='bold' gutterBottom component="div">
                  None
                </Typography>
                <Typography variant="body2" color="text.secondary" textAlign='center'>
                  Continue writing your story in the editor
                </Typography>
              </CardContent>
          </CardActionArea>
        </Card>
    );
}