import { Box, Typography } from "@mui/material";
import { ToolTextArea } from './Elements'

type CharacterListFormProps = {
    error: boolean
    concept: string
    handleConceptChange: (value: string) => void
}

export default function CharacterListForm({ error, concept, handleConceptChange }: CharacterListFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Story Concept</Typography>
                <ToolTextArea error={!concept.length && error} placeholder={'Two friends find something unusual in the trash, which changes their lives.'} style={{backgroundColor: '#fff'}} value={concept} onChange={(event) => handleConceptChange(event.target.value)} />
            </Box>
        </>
    )
}