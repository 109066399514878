import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Button, Card, CardActionArea, CircularProgress, Grid, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useContext, useEffect, useState } from 'react';
import moment from 'moment'
import type { Document } from '../../api/types/documents';
import { fetchDocuments } from '../../api/documents';
import { CreateModal } from '../editor/CreateModal';
import SomethingWentWrong from '../error/SomethingWentWrong';
import { useNavigate } from 'react-router-dom';
import logo from '../auth/wordmuse_logo.svg'
import { UserMenu } from '../layout/UserMenu';
import { WindowSizeContext, WindowSizeContextValue } from '../../context/WindowSizeContext';

const useStyles = makeStyles((theme: any) => ({
    stretch: { height: '100%' },
    root: {
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    }
}));

type DocumentsProps = {
    create: (newDocument: Document) => void
    goToEditor: (id: string) => void
}

export default function Documents({ create, goToEditor }: DocumentsProps) {
    const { windowSize } = useContext(WindowSizeContext) as WindowSizeContextValue
    const snapLayout = windowSize.innerWidth < 900
    const shrinkGridItems = windowSize.innerWidth < 800
    const tabIconsOnly = windowSize.innerWidth < 700
    const hideToolbar = windowSize.innerWidth < 550
    const mobile = windowSize.innerWidth < 500
    
    const classes = useStyles();
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>(null)
    const [data, setData] = useState<Document[] | []>([])
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableView, setTableView] = useState(false)

    const handleOpenCreateModal = () => {
      setCreateModalOpen(true);
    };
  
    const handleCloseCreateModal = () => {
      setCreateModalOpen(false);
    };

    // TO DO: Remove checks for createdAt & updatedAt - all documents will have those fields
    const rows = data ? data.map((item, index) => {
        return { id: item._id, title: item.title || 'Untitled', createdAt: item.createdAt ? moment(item.createdAt).format("MMM D, YYYY") : '', updatedAt: item.updatedAt ? moment(item.updatedAt).format("MMM D, YYYY") : '' }
    }) : []

    useEffect(() => {
        setLoading(true)
        fetchDocuments()      
            .then(response => {
                setData(response.data)
                setLoading(false)
            })
            .catch(err => {
                setError(err)
                setLoading(false)
            })
    }, [])

    if (error) {
        return <SomethingWentWrong />
    }

    let cardWidth = 218, cardHeight = 250
    if (shrinkGridItems) {
        cardWidth = 174
        cardHeight = 200
    }
    if (mobile) {
        cardWidth = 150
        cardHeight = 172
    }

    const cardWidthWithMargin = cardWidth + 16

    const cardsPerRow = shrinkGridItems 
        ? Math.floor((windowSize.innerWidth) / cardWidthWithMargin)
        : Math.floor((windowSize.innerWidth - 96) / cardWidthWithMargin)
    const gridMaxWidth = cardsPerRow * cardWidthWithMargin

    const list = (
            <Box style={{ display: 'flex', flexWrap: 'wrap', maxWidth: cardsPerRow > 5 ? (cardWidthWithMargin * 5) : gridMaxWidth }}>
                <Card style={{height: cardHeight, flexBasis: 'calc(20% - 1rem)', margin: '0.5rem', overflowY: 'hidden', position: 'relative', minWidth: cardWidth, maxWidth: cardWidth }} onClick={() => handleOpenCreateModal()}>
                    <CardActionArea style={{height: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <AddCircleOutlineIcon color='primary' fontSize='large' />
                    </CardActionArea>
                </Card>

                {data.length > 0 && data.map((document, index) => {
                    return (
                        <Card key={index} style={{height: cardHeight, flexBasis: 'calc(20% - 1rem)', margin: '0.5rem', marginBottom: index === data.length-1 ? '2rem' : '0.5rem', overflowY: 'hidden', position: 'relative', minWidth: cardWidth, maxWidth: cardWidth }} onClick={() => goToEditor(document._id)}>
                            <CardActionArea style={{height: '100%', padding: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                <Box>
                                    <Typography fontWeight='bold' style={{marginBottom: '1rem'}}>{document.title || 'Untitled'}</Typography>
                                    <Typography fontSize={hideToolbar ? 12 : 16}>{document.preview}</Typography>
                                </Box>
                                {/* Hack to add bottom padding */}
                                {!mobile && (
                                    <Box style={{ height: '3rem', width: '100%', bottom: 0, position: 'absolute', backgroundColor: '#fff', display: 'flex', alignItems: 'center' }}>
                                        <Typography fontSize={12}>Modified: {moment(document.updatedAt).format("MMM D, YYYY")}</Typography>
                                    </Box>
                                )}
                                {mobile && <Box style={{height: '0.25rem', width: '100%', bottom: 0, position: 'absolute', backgroundColor: '#fff'}}></Box>}
                            </CardActionArea>
                        </Card>
                    )
                })}
            </Box>
        )

    const loadingSpinner = (
        <Box display='flex' flex={1} position='absolute' top='50%' left='calc(50% - 20px)'>
            <CircularProgress color="secondary" />
        </Box>
    )

    return (
        <Box style={{paddingBottom: 0, backgroundColor: '#ba55d315', minHeight: '100%', position: 'relative' }}>
            <Box display='flex' justifyContent='space-between'>
                <img src={logo} height={40} width={150} onClick={() => navigate('/')} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />

                <Box display='flex' justifyContent='flex-end' alignItems='center' padding='1rem' paddingTop='0.75rem'>
                    <Box style={{marginRight: '1rem'}}>
                        <Button variant='contained' onClick={handleOpenCreateModal}>New</Button>
                    </Box>

                    <UserMenu />
                </Box>
            </Box>

            <Box style={{height: '100%', paddingBottom: 0, paddingRight: hideToolbar ? 0 : '1rem', paddingLeft: hideToolbar ? 0 : '1rem', flex: 1 }}>
                <Grid className={classes.stretch} container spacing={0}>
                    <Grid className={classes.stretch} item xs={12} md={12} lg={12}>
                        <Box
                            className={classes.stretch}
                            sx={{
                                p: snapLayout ? 0 : 4,
                                paddingTop: '1.5rem',
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                                alignItems: 'center'
                            }}
                        >
                            {loading ? loadingSpinner : list}
                        </Box>
                    </Grid>
                </Grid>

                <CreateModal close={handleCloseCreateModal} open={createModalOpen} />
            </Box>
        </Box>
    )
}