import { Box, Typography } from "@mui/material";
import { ToolInput, ToolTextArea } from './Elements'

type ConceptFormProps = {
    error: boolean
    setting: string
    character: string
    handleSettingChange: (value: string) => void
    handleCharacterChange: (value: string) => void
}

export default function ConceptForm({ error, setting, character, handleSettingChange, handleCharacterChange }: ConceptFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Setting</Typography>
                <ToolInput error={!setting.length && error} placeholder={'A desert island in the Pacific'} value={setting} onChange={(event) => handleSettingChange(event.target.value)} />
            </Box>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Protagonist</Typography>
                <ToolTextArea error={!character.length && error} placeholder={'The sole survivor of a shipwreck washes up on shore.'} value={character} onChange={(event) => handleCharacterChange(event.target.value)} />
            </Box>
        </>
    )
}