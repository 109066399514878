import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createDocument } from '../../api/documents';
import { useNavigate } from 'react-router-dom';
import { Button as MuiButton, CircularProgress, TextField, Typography } from '@mui/material';
import type { Document } from '../../api/types/documents'
import { useState } from 'react';
import MuiAlert from '@mui/material/Alert';

type CreateModalProps = {
    clearPreviousDocument?: () => void
    close: () => void
    open: boolean
    resetEditor?: (document: Document) => void
}

export function CreateModal ({ clearPreviousDocument, close, open, resetEditor }: CreateModalProps) {
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [error, setError] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [emptyTitle, setEmptyTitle] = useState(false)

    const handleKeyPress = (e: { keyCode: number; }) => {
      if(e.keyCode == 13){
        confirmCreateDocument()
      }
    }

    const confirmCreateDocument = async () => {
      if (!title.length) {
        setEmptyTitle(true)
        return
      }

      !!clearPreviousDocument && clearPreviousDocument()
      setLoading(true)
      setError(null)
      createDocument(title)
          .then((response) => {
              navigate(`/documents/${response.data._id}`)
              !!resetEditor && resetEditor(response.data)
              setLoading(false)
              handleCloseModal()
          })
          .catch(err => {
              setError(err)
              setLoading(false)
          })
    }

    const handleTitleChange = (value: string) => {
      if (value.length === 0) {
          setEmptyTitle(true)
      } else {
          setEmptyTitle(false)
      }
      setTitle(value)
    }

    const handleCloseModal = () => {
      setEmptyTitle(false)
      setTitle('')
      close()
    }

    return (
        <Dialog
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        {error && <MuiAlert severity='error' onClose={() => setError(null)}>{error?.response?.data?.message}</MuiAlert>}
        <DialogTitle id="alert-dialog-title">
          Create new document
        </DialogTitle>
        <DialogContent style={{minWidth: 300}}>
          <DialogContentText id="alert-dialog-description">
          <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginTop: '0.5rem'}}>Title</Typography>
            <TextField 
              fullWidth 
              disabled={loading} 
              onChange={(event) => handleTitleChange(event.target.value)} 
              value={title} 
              inputProps={{ style: { padding: '0.75rem' } }} 
              error={emptyTitle}
              helperText={emptyTitle ? 'Title cannot be blank' : ''}
              onKeyDown={handleKeyPress}
            />
          </DialogContentText>
          {loading && <CircularProgress color='secondary' style={{position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)'}} />}
        </DialogContent>
        <DialogActions>
          <MuiButton disabled={loading} onClick={handleCloseModal}>Cancel</MuiButton>
          <MuiButton variant='contained' disabled={loading} color='info' onClick={confirmCreateDocument} autoFocus>
            Create
          </MuiButton>
        </DialogActions>
      </Dialog>
    )
}