// TO DO: Add TS
// @ts-nocheck

import {
    Transforms,
    Element as SlateElement,
    Node,
    Descendant
  } from 'slate'
import { jsx } from 'slate-hyperscript'

export type ParagraphElement = {
    type: 'paragraph'
    align?: string
    children: Descendant[]
  }
  
export type TitleElement = { type: 'heading-one'; children: Descendant[] }
  

const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'quote' }),
    H1: () => ({ type: 'heading-one' }),
    H2: () => ({ type: 'heading-two' }),
    H3: () => ({ type: 'heading-three' }),
    H4: () => ({ type: 'heading-four' }),
    H5: () => ({ type: 'heading-five' }),
    H6: () => ({ type: 'heading-six' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
}
  
// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
    CODE: () => ({ code: true }),
    DEL: () => ({ strikethrough: true }),
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    S: () => ({ strikethrough: true }),
    STRONG: () => ({ bold: true }),
    U: () => ({ underline: true }),
}

export const deserialize = el => {
    if (el.nodeType === 3) {
      return el.textContent
    } else if (el.nodeType !== 1) {
      return null
    } else if (el.nodeName === 'BR') {
      return '\n'
    }
  
    const { nodeName } = el
    let parent = el
  
    if (
      nodeName === 'PRE' &&
      el.childNodes[0] &&
      el.childNodes[0].nodeName === 'CODE'
    ) {
      parent = el.childNodes[0]
    }
    let children = Array.from(parent.childNodes)
      .map(deserialize)
      .flat()
  
    if (children.length === 0) {
      children = [{ text: '' }]
    }
  
    if (el.nodeName === 'BODY') {
      return jsx('fragment', {}, children)
    }
  
    if (ELEMENT_TAGS[nodeName]) {
      const attrs = ELEMENT_TAGS[nodeName](el)
      return jsx('element', attrs, children)
    }
  
    if (TEXT_TAGS[nodeName]) {
      const attrs = TEXT_TAGS[nodeName](el)
      return children.map(child => jsx('text', attrs, child))
    }
  
    return children
}

export const withHtml = editor => {
    const { insertData, isInline, isVoid } = editor
  
    editor.isInline = element => {
      return element.type === 'link' ? true : isInline(element)
    }
  
    editor.isVoid = element => {
      return element.type === 'image' ? true : isVoid(element)
    }
  
    editor.insertData = data => {
      const html = data.getData('text/html')
  
      if (html) {
        const parsed = new DOMParser().parseFromString(html, 'text/html')
        const fragment = deserialize(parsed.body)
        Transforms.insertFragment(editor, fragment)
        return
      }
  
      insertData(data)
    }
  
    return editor
}

export const withLayout = editor => {
    const { normalizeNode } = editor
  
    editor.normalizeNode = ([node, path]) => {
      if (path.length === 0) {
        if (editor.children.length < 1) {
          const title: TitleElement = {
            type: 'heading-one',
            children: [{ text: 'Untitled' }],
          }
          Transforms.insertNodes(editor, title, { at: path.concat(0) })
        }
  
        if (editor.children.length < 2) {
          const paragraph: ParagraphElement = {
            type: 'paragraph',
            children: [{ text: '' }],
          }
          Transforms.insertNodes(editor, paragraph, { at: path.concat(1) })
        }
  
        for (const [child, childPath] of Node.children(editor, path)) {
          let type: string
          const slateIndex = childPath[0]
          const enforceType = type => {
            if (SlateElement.isElement(child) && child.type !== type) {
              const newProperties: Partial<SlateElement> = { type }
              Transforms.setNodes<SlateElement>(editor, newProperties, {
                at: childPath,
              })
            }
          }
  
          switch (slateIndex) {
            case 0:
              type = 'heading-one'
              enforceType(type)
              break
            case 1:
              type = 'paragraph'
              enforceType(type)
            default:
              break
          }
        }
      }
  
      return normalizeNode([node, path])
    }
  
    return editor
}
  