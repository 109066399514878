import { Box, Typography } from "@mui/material";
import { ToolInput } from './Elements'

type NoToolFormProps = {
    tone: string
    handleToneChange: (value: string) => void
}

export default function NoToolForm({ tone, handleToneChange }: NoToolFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Box display='flex'>
                    <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem', marginRight: '0.25rem'}}>Tone</Typography>
                    <Typography fontSize={14} style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem'}}>(optional)</Typography> 
                </Box>
                <ToolInput placeholder={'Whimsical'} value={tone} onChange={(event) => handleToneChange(event.target.value)} />
            </Box>
        </>
    )
}