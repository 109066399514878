import axios from './axios/axios'
import { BASE_URL, getHeaders } from './const/const'
import type { Document } from './types/documents'

export const fetchDocuments = () => {
    return axios.get(`${BASE_URL}text-documents`)
}

export const fetchDocument = (id: string) => {
    return axios.get(`${BASE_URL}text-documents/${id}`, getHeaders())
}

export const createDocument = (title: string = '') => {
    const newDocument = {
        title: '',
        content: [
            {
              type: 'heading-one',
              children: [{ text: '' }],
            },
            {
              type: 'paragraph',
              children: [{ text: '' }],
            },
        ]
    }

    newDocument.title = title
    newDocument.content[0].children[0].text = title

    return axios.post(`${BASE_URL}text-documents`, JSON.stringify(newDocument), getHeaders())
}

export const createDefaultDocument = () => {
    return axios.post(`${BASE_URL}text-documents/default`, {}, getHeaders())
}

export const updateDocument = (document: Document) => {
    return axios.patch(`${BASE_URL}text-documents/${document._id}`, JSON.stringify(document), getHeaders())
}

export const deleteDocument = (document: Document) => {
    return axios.delete(`${BASE_URL}text-documents/${document._id}`, getHeaders())
}