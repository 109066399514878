import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from './wordmuse_logo.svg'
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress, Paper } from '@mui/material';
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext';
import Cookie from 'js-cookie'
import { logout, sendVerificationEmail, verifyEmailAddress } from '../../api/auth';
import { createDefaultDocument } from '../../api/documents';
import { User } from '../../api/types/auth';
import { removeCookie } from '../../api/utils/utils';

const theme = createTheme();

export default function VerifyEmailSuccess() {
    const navigate = useNavigate()
    const params = useParams()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [verified, setVerified] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [emailSending, setEmailSending] = useState(false)
    const [defaultDocCreated, setDefaultDocCreated] = useState(false)
    const { currentUser, setCurrentUser, setAuthenticated } = useContext(CurrentUserContext) as CurrentUserContextValue

    useEffect(() => {
        if (params.verificationToken && currentUser && !currentUser.emailVerified && !verified) {
            verifyEmailAddress({ userId: currentUser._id, verificationToken: params.verificationToken })
                .then(response => {
                    // TO DO: Make sure this is only called if user doesn't have one - ideally done on the backend
                    if (!defaultDocCreated) {
                        createDefaultDocument()
                            .then(() => {
                                setDefaultDocCreated(true)
                            })
                    }
                    setCurrentUser(response.data)
                    setAuthenticated(true)
                    setVerified(true)
                    setLoading(false)
                })
                .catch(err => {
                    // TO DO
                    setError(err)
                    setLoading(false)
                })
        }
    }, [])

    const signIn = () => {
        navigate('/')
    }

    const handleSubmit = async () => {
        if (currentUser) {
            setEmailSending(true)
            sendVerificationEmail({ userId: currentUser._id, email: currentUser.email })
                .then(() => {
                    setEmailSent(true)
                    setEmailSending(false)
                })
                .catch(err => {
                    // TO DO: Display error
                    setEmailSending(false)
                })
        }
    }

    const signOut = () => {
        logout()
            .finally(() => {
                removeCookie()
                setAuthenticated(false)
                setCurrentUser(null)
            })
    }

    if (loading) {
        return (
            <Container component="main" maxWidth="xs">
                <CircularProgress />
            </Container>
        )
    }

    return (
        <ThemeProvider theme={theme}>
        <Box display='flex' justifyContent='space-between'>
            <img src={logo} height={40} width={150} onClick={signIn} style={{marginTop: '1rem', marginLeft: '2rem', cursor: 'pointer'}} />
            <Button onClick={signOut} style={{marginRight: '2rem', marginTop: '0.5rem'}}>Logout</Button>
        </Box>

        <Container component="main" maxWidth="xs" sx={{ marginTop: emailSent ? 0 : 8 }}>
            <CssBaseline />
            {emailSent && <MuiAlert severity='success' onClose={() => setEmailSent(false)} style={{marginBottom: '1rem'}}>Verification email sent</MuiAlert>}
            <Paper style={{padding: '2rem', paddingTop: '2rem'}}>
                
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: error ? 'secondary.main' : 'green' }}>
                        {error ? <LockOutlinedIcon /> : <DoneOutlinedIcon />}
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {error ? 'Invalid Email Verification Link' : 'Account Verified'}
                    </Typography>

                    <Box marginTop='0.5rem' display='flex' flexDirection='column' justifyContent='center' width='100%'>
                        <Typography style={{marginBottom: '1rem', textAlign: 'center'}}>{error ? 'This link has expired or is invalid.' : 'Welcome aboard! Let\'s start writing.'}</Typography>

                        {error && !currentUser?.emailVerified && (
                            <LoadingButton
                                loading={emailSending}
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={handleSubmit}
                        >
                                Resend email
                            </LoadingButton>
                        )}

                        {verified && <Button color='secondary' variant='contained' onClick={signIn} style={{marginTop: '1rem'}}>Start</Button>}
                    </Box>
                </Box>
            </Paper>
        </Container>
        </ThemeProvider>
    );
}