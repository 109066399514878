import React, { Dispatch, SetStateAction } from 'react'

type WindowSize = { 
    innerWidth: number, 
    innerHeight: number 
}

export interface WindowSizeContextValue {
    windowSize: WindowSize
    setWindowSize: Dispatch<SetStateAction<WindowSize>>
}

export const WindowSizeContext = React.createContext<WindowSizeContextValue | null>(null)