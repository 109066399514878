import { Box, Button, CircularProgress, IconButton, Typography } from "@mui/material"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useState } from "react";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

type OutputProps = {
    currentTool: string
    // TO DO: Type for completion data
    data: any
    loading: boolean
    error: any
    insertIntoDocument: (value: string) => void
}

const tools = [
    { name: 'outline-builder', title: 'Plot Outline', description: 'Build an outline for your story' },
    { name: 'character-list', title: 'Character List', description: 'Find interesting characters for your story'},
    { name: 'character-backstory', title: 'Backstory', description: 'Learn about your character\'s past'},
    { name: 'concept', title: 'Story Concept', description: 'Find a concept for your new story'},
    { name: 'worldbuilding', title: 'Worldbuilding', description: 'Explore the world where your story takes place'},
    { name: 'story-title', title: 'Title', description: 'Find a great title for your story'},
    { name: 'essay-outline', title: 'Essay Outline', description: 'Generate an essay outline for any topic'},
    { name: 'description', title: 'Description', description: 'Describe a person, place, or thing'},
    { name: 'intro', title: 'Story Intro', description: 'Kick off your story with an intro paragraph'},
    { name: 'expand', title: 'Expand Text', description: 'Expand a word or sentence into a paragraph'},
]

const numberAlphabetMap: {[key: number]: string} = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E'
}

export function Output({ currentTool, data, loading, error, insertIntoDocument }: OutputProps) {
    const [addedOutputIndices, setAddedOutputIndices] = useState<number[]>([])
    // const [copyTooltipMessage, setCopyTooltipMessage] = useState('Copy')

    const insert = (index: number) => {
        const text: string = data.choices[index].text.trim()

        insertIntoDocument(text)

        if (addedOutputIndices.indexOf(index) === -1) {
            setAddedOutputIndices([...addedOutputIndices, index])
        }
    }

    const toolTitle = data && data.tool 
        ? tools.find(item => item.name === data.tool)?.title 
        : tools.find(item => item.name === currentTool)?.title

    return (
        <Box display='flex' flexDirection='column' maxHeight='100%'>
            <Box style={{ height: 54, borderBottom: '2px solid #eee', display: 'flex', alignItems: 'center', paddingLeft: '1.5rem' }}>
                <strong>Tools</strong>
                {' '}
                <ChevronRightIcon />
                {' '}
                <strong>{toolTitle || 'Continue Writing'}</strong>
            </Box>
            <Box padding='1.5rem' paddingTop='1.5rem' style={{ position: 'relative', overflowY: 'scroll', height: 'calc(100vh - 114px)'}}>
                <Box flex={1} display={loading ? 'flex' : 'none'} style={{position: 'absolute', top: 'calc(50% - 100px)', left: 'calc(50% - 145px)', zIndex: 999 }}>
                    <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center' style={data ? { backgroundColor: 'white', padding: '2rem', borderRadius: 12, boxShadow: '2px 3px #888888'} : {}}>
                        <CircularProgress color="secondary" />
                        <Typography color='secondary' fontWeight='bold' style={{ marginTop: '1rem' }}>Generating output - Hang tight!</Typography>
                    </Box>
                </Box>

                {error && (
                    <Box display='flex' flex={1} justifyContent='center' alignItems='center' height='100%'>
                        <ErrorOutlineIcon color='error' style={{marginRight: '0.25rem'}} />
                        <Typography>{'Failed to generate text. Please try again.'}</Typography>
                    </Box>
                )}

                {!data && !loading && !error && (
                    <Box display='flex' flex={1} justifyContent='center' alignItems='center' height='100%'>
                        <Typography>Press Write to generate text</Typography>
                    </Box>
                )}

                {data && data.flagged && !error && (
                    <Box display='flex' flex={1} justifyContent='center' alignItems='center' height='100%'>
                        <ErrorOutlineIcon color='error' style={{marginRight: '0.25rem'}} />
                        <Typography>Output contains sensitive content. Either try again or modify your input text.</Typography>
                    </Box>
                )}

                {data && !data.flagged && !error && data.choices.map((choice: any, index: number) => {
                    const added = addedOutputIndices.indexOf(index) > -1

                    return (
                        <Box key={index} style={{marginBottom: '1.5rem' }}>
                            <Box style={{position: 'relative', backgroundColor: '#ba55d315', borderRadius: 12, minHeight: '4rem', display: 'flex', flexDirection: 'column', padding: '1.5rem 1.5rem' }}>
                                {data.choices.length > 1 && <Typography fontWeight='bold' style={{color: loading ? '#ccc' : 'inherit', marginBottom: '1rem'}}>Option {(index + 1)}</Typography>}

                                <Box>
                                    <Typography>
                                        <Typography fontSize={15} style={{color: loading ? '#ccc' : 'inherit', whiteSpace: 'pre-line'}}>
                                            {choice.text.trim()}
                                        </Typography>
                                    </Typography>
                                </Box>

                                <Box style={{marginTop: '1.5rem'}}>
                                    <Button variant='outlined' color='primary' disabled={loading || added} onClick={() => insert(index)}>
                                        {added ? <CheckCircleIcon /> : <AddCircleOutlineIcon />}
                                        <span style={{marginLeft: '0.25rem', fontSize: 16}}>{added ? 'Added to document' : 'Add to document'}</span>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}