import React, { useRef, useEffect, Ref, PropsWithChildren, useContext } from 'react'
import { useSlate, useFocused } from 'slate-react'
import {
  Editor,
  Range,
} from 'slate'
import ReactDOM from 'react-dom'
import { cx, css } from '@emotion/css'
import ExpandIcon from '@mui/icons-material/Expand';
import { Button, Typography } from '@mui/material'
import { CurrentUserContext, CurrentUserContextValue } from '../../context/CurrentUserContext'
import { CHARS_PER_WORD } from '../../const/const'

interface BaseProps {
    className: string
    [key: string]: unknown
  }
type OrNull<T> = T | null

type HoveringToolbarProps = {
    expandText: (value: string) => void
}

export default function HoveringToolbar({ expandText }: HoveringToolbarProps) {
    const ref = useRef<HTMLDivElement | null>()
    const editor = useSlate()
    const inFocus = useFocused()

    const { currentUser } = useContext(CurrentUserContext) as CurrentUserContextValue
    // TO DO: Util function
    const outputCallsDisabled = currentUser ? (currentUser.wordsQuota - currentUser.wordsUsed <= 0): true

    useEffect(() => {
      const el = ref.current
      const { selection } = editor
  
      if (!el) {
        return
      }
  
      if (
        !selection ||
        !inFocus ||
        Range.isCollapsed(selection) ||
        Editor.string(editor, selection) === ''
      ) {
        el.removeAttribute('style')
        return
      }

      const text = Editor.string(editor, selection)
      const maxWordsToHighlight = 250
      const maxLength = CHARS_PER_WORD * maxWordsToHighlight

      if (text.length > maxLength) {
        el.style.display = 'none'
        return
      } else {
        el.style.display = 'inherit'
      }

      const domSelection = window.getSelection()
      // @ts-ignore
      const domRange = domSelection.getRangeAt(0)
      const rect = domRange.getBoundingClientRect()
      el.style.opacity = '1'
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
      el.style.left = `${rect.left +
        window.pageXOffset -
        el.offsetWidth / 2 +
        rect.width / 2}px`
    })

    const expand = () => {
        if (!outputCallsDisabled) {
            const { selection } = editor
            // @ts-ignore
            const text = Editor.string(editor, selection)
            expandText(text)
        }
    }
  
    return (
      <Portal>
        <Menu
            // @ts-ignore
          ref={ref}
          className={css`
            // padding: 8px 7px 6px;
            position: absolute;
            z-index: 1;
            top: -10000px;
            left: -10000px;
            margin-top: -6px;
            opacity: 0;
            // background-color: #222;
            border-radius: 4px;
            transition: opacity 0.75s;
          `}
          onMouseDown={(e: { preventDefault: () => void }) => {
            // prevent toolbar from taking focus away from editor
            e.preventDefault()
          }}
        >
            <Button onClick={expand} variant='contained' disabled={outputCallsDisabled} color='secondary' style={{color: '#fff', display: 'flex', padding: '0.25rem', paddingRight: '0.5rem'}}>
                <ExpandIcon style={{marginRight: '0.125rem'}} />
                <Typography>Expand</Typography>
            </Button>
            {/* <Box style={{color: '#fff', display: 'flex'}}>
                <ExpandIcon style={{marginRight: '0.125rem'}} />
                <Typography>Expand</Typography>
            </Box> */}
        </Menu>
      </Portal>
    )
  }

  const Menu = React.forwardRef(
    (
      { className, ...props }: PropsWithChildren<BaseProps>,
      ref: Ref<OrNull<HTMLDivElement>>
    ) => (
      <div
        {...props}
        // @ts-ignore
        ref={ref}
        className={cx(
          className,
          css`
            & > * {
              display: inline-block;
            }
            & > * + * {
              margin-left: 15px;
            }
          `
        )}
      />
    )
  )
  
  // @ts-ignore
  const Portal = ({ children }) => {
    return typeof document === 'object'
      ? ReactDOM.createPortal(children, document.body)
      : null
  }