import { Box, Typography } from "@mui/material";
import { ToolTextArea } from './Elements'

type DefaultFormProps = {
    error: boolean
    description: string
    handleDescriptionChange: (value: string) => void
}

export default function DefaultForm({ error, description, handleDescriptionChange }: DefaultFormProps) {
    return (
        <>
            <Box display='flex' flexDirection='column' style={{marginBottom: '1rem'}}>
                <Typography fontSize={14} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '0.5rem'}}>Story Concept</Typography>
                <ToolTextArea error={!description.length && error} placeholder={'A man wakes up in a forest with no idea how he got there.'} value={description} onChange={(event) => handleDescriptionChange(event.target.value)} />
            </Box>
        </>
    )
}