import { styled, TextField } from '@mui/material'

type ToolInputProps = {
    error?: boolean
    placeholder: string
    onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    value: string
    style?: React.CSSProperties | undefined
}

// Helper text bg color defaults to input bg color
const StyledTextField = styled(TextField)`
.MuiFormHelperText-root {
    background-color: #f5f5f5;
    padding: 3px 14px 0 14px;
    margin: 0px;
}`

export function ToolInput(props: ToolInputProps) {
    return <StyledTextField {...props} helperText={props.error ? 'Field cannot be blank' : ''} style={{backgroundColor: '#fff', paddingBottom: 0}} inputProps={{ maxLength: 100, style: { padding: '0.75rem' } }} />
}

export function ToolTextArea(props: ToolInputProps) {
    return <StyledTextField helperText={props.error ? 'Field cannot be blank' : ''} multiline minRows={3} maxRows={3} inputProps={{ maxLength: 200 }} style={{backgroundColor: '#fff'}} {...props} />
}