import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Routes, Route, unstable_HistoryRouter as HistoryRouter, useLocation, useNavigate } from 'react-router-dom';
import history from './routes/history';
import Cookie from 'js-cookie'
import Layout from './components/layout/Layout';
import SignUp from './components/auth/SignUp';
import SignIn from './components/auth/SignIn';
import { CurrentUserContext } from './context/CurrentUserContext';
import { User } from './api/types/auth';
import { fetchCurrentUser } from './api/auth';
import { Box, width } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import { getCookie, removeCookie } from './api/utils/utils';
import '@stripe/stripe-js'
import { WindowSizeContext } from './context/WindowSizeContext';

const mdTheme = createTheme({
  palette: {
    background: {
      default: '#ba55d31'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    fontFamily: [
      'Inter', 'sans-serif'
    ].join(',')
  }
});

type ContentProps = {
  windowTooSmall: boolean
}

const authRoutePaths = ['/signin', '/signup', '/forgot-password']

function Content ({ windowTooSmall }: ContentProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [authenticated, setAuthenticated] = useState(false)

  const tokens = getCookie()
  const accessToken = tokens?.accessToken

  useEffect(() => {
    if (!currentUser) {
      if (accessToken) {
        fetchCurrentUser()
          .then(response => {
            setAuthenticated(true)
            setCurrentUser(response.data)
            navigate(location.pathname === '/' ? '/documents' : location.pathname)
        })
        // TO DO: Figure out error handling on backend
        .catch(err => {
          removeCookie()
          setAuthenticated(false)
          setCurrentUser(null)
        })
      } else {
        const isAuthPath = authRoutePaths.indexOf(location.pathname) > -1 || location.pathname.startsWith('/reset-password')
        navigate(isAuthPath ? location.pathname : '/')
      }
    }
  }, [accessToken])

  const windowSizePlaceholder = (
    <Box height='100vh' width='100%' display='flex' alignItems='center' justifyContent='center'>
      <span style={{wordWrap: 'break-word', padding: '0 2rem'}}>Your window size is too small! Please use a window at least 900px wide.</span>
    </Box>
  )

  // if (windowTooSmall) {
  //   return windowSizePlaceholder
  // }

  const routes = (
    <Routes>
      <Route path="/signin" element={<SignIn setAuthenticated={setAuthenticated} />} />
      <Route path="/signup" element={<SignUp setAuthenticated={setAuthenticated} />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:resetPasswordToken" element={<ResetPassword />} />
      <Route path="/" element={<Navigate to="/signin" replace />} />
    </Routes>
  )

  return (
    <CurrentUserContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        currentUser,
        setCurrentUser
      }}
    >
      {authenticated && currentUser && accessToken ? <Layout /> : routes}
    </CurrentUserContext.Provider>
  )
}

export default function App() {
  const getWindowSize = () => {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight}
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const widthTooSmall = windowSize.innerWidth < 900

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize())
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, [])

  return (
    <HistoryRouter history={history}>
      <ThemeProvider theme={mdTheme}>
        <WindowSizeContext.Provider
          value={{
            windowSize,
            setWindowSize
          }}
        >
          <Content windowTooSmall={widthTooSmall} />
        </WindowSizeContext.Provider>
      </ThemeProvider>
    </HistoryRouter>
  );
}
