import Fab from '@mui/material/Fab';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { sendFeedbackEmail } from '../../api/feedback';
import { WindowSizeContext, WindowSizeContextValue } from '../../context/WindowSizeContext';

const StyledTextField = styled(TextField)`
.MuiFormHelperText-root {
    background-color: #f5f5f5;
    padding: 3px 14px 0 14px;
    margin: 0px;
    font-size: 14px;
}`

export function Feedback() {
    const { windowSize } = useContext(WindowSizeContext) as WindowSizeContextValue
    const snapLayout = windowSize.innerWidth < 900
    const tabIconsOnly = windowSize.innerWidth < 700

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [text, setText] = useState('')
    const [buttonHover, setButtonHover] = useState(false)

    const close = () => {
        setOpen(false)
        setSent(false)
        setText('')
    }

    const handleTextChange = (value: string) => setText(value)

    const sendFeedback = async () => {
        setLoading(true)
        sendFeedbackEmail({ text })
            .then(() => {
                setSent(true)
                setLoading(false)
            })
            .catch(err => {
                // TO DO: Display error
                setLoading(false)
            })
    }

    const showButtonText = !snapLayout || buttonHover

    return (
        <>
            {!tabIconsOnly && (<Box style={{ position: 'fixed', bottom: '1rem', left: '1.5rem' }}>
                <Fab color='info' variant='extended' onClick={() => setOpen(true)} onMouseOver={() => setButtonHover(true)} onMouseLeave={() => setButtonHover(false)}>
                    <FavoriteIcon color='error' sx={{ mr: showButtonText ? 1 : 0 }} />
                    {showButtonText ? 'Give Feedback' : ''}
                </Fab>
            </Box>)}

            <Dialog
                open={open}
                onClose={close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">
                    {sent ? 'Thanks!' : 'Help Us Improve!'}
                </DialogTitle>

                {sent && (
                    <DialogContent style={{maxWidth: 350}}>
                        <Typography fontSize={16} style={{color: 'rgb(0,0,0,0.7)', marginBottom: '1rem', marginTop: '0.5rem'}}>Your message has been received.</Typography>
                    </DialogContent>
                )}

                {!sent && (
                    <DialogContent style={{maxWidth: 350}}>
                        <Typography fontSize={16} fontWeight='bold' style={{color: 'rgb(0,0,0,0.7)', marginBottom: '1rem', marginTop: '0.5rem'}}>Tell us your honest thoughts, report a bug, or suggest a new feature.</Typography>
                        <StyledTextField fullWidth autoFocus multiline minRows={4} maxRows={10} inputProps={{ maxLength: 4000 }} placeholder='Your thoughts...' value={text} onChange={(e) => handleTextChange(e.target.value)} />
                    </DialogContent>
                )}

                <DialogActions>
                    <Button onClick={close} disabled={loading}>{sent ? 'Close' : 'Cancel'}</Button>
                    {!sent && <Button variant='contained' onClick={sendFeedback} disabled={loading || !text.length}>Send</Button>}
                </DialogActions>
            </Dialog>
        </>
    )
}