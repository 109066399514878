import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { CheckoutModal } from '../layout/UserMenu';

type OutOfWordsModalProps = {
    open: boolean
    close: () => void
}

export function OutOfWordsModal({ open, close }: OutOfWordsModalProps) {
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)

  return (
    <>
      <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Out of Words
        </DialogTitle>
        <DialogContent style={{width: 300}}>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ marginBottom: '1rem' }}>Oh no! You've run out of words for the month.</Typography>
            <Typography style={{ marginBottom: '1.5rem' }}>Upgrade your plan to get more words, or wait until the end of your monthly cycle.</Typography>
          </DialogContentText>

          <Button fullWidth variant='contained' onClick={() => setCheckoutModalOpen(true)}>Upgrade Account</Button>
        </DialogContent>
      </Dialog>

      <CheckoutModal isOpen={checkoutModalOpen} close={() => setCheckoutModalOpen(false)} />
    </>
  )
}